import React, {Component} from 'react';
import './Site.css';

const DonateSite = (props) => {
  return (
    <div id="site-body">
      <p><a id="site-title" href={props.link} target="_blank">{props.name}</a></p>
      <img id="site-logo" src={props.image} height="200" />
      <p id="site-desc">{props.desc}</p>
    </div>
  )
}

export default DonateSite;
