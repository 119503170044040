import React, {Component} from 'react';
import './Site.css';

const SmallSite = (props) => {
  return (
    <div id="site-body">
      <p><a id="site-title" href={props.link} target="_blank">{props.name}</a></p>
      <p id="site-desc">{props.desc}</p>
      <p id="site-date">{props.date}</p>
    </div>
  )
}

export default SmallSite;
