import React from 'react';
const addButton = () => {
  return(
    <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">

     <g>
      <title>background</title>
      <rect fill="none" id="canvas_background" height="102" width="102" y="-1" x="-1"/>
     </g>
     <g>
      <title>Layer 1</title>
      <ellipse ry="40" rx="40" id="svg_4" cy="50" cx="50" stroke-width="5" fill="#7f7f7f"/>
      <ellipse stroke="#444" ry="45" rx="45" id="svg_1" cy="50" cx="50" stroke-width="5" fill="none"/>
      <line stroke="#444" stroke-linecap="round" stroke-linejoin="null" id="svg_2" y2="50" x2="72.375" y1="50" x1="27.624999" stroke-opacity="null" stroke-width="5" fill="none"/>
      <line transform="rotate(90 50,50) " stroke="#444" stroke-linecap="round" stroke-linejoin="null" id="svg_3" y2="50.000001" x2="72.375" y1="50.000001" x1="27.624999" stroke-opacity="null" stroke-width="5" fill="none"/>
     </g>
    </svg>
  );
}
export default addButton;
