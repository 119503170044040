import React, {Component} from 'react';
import Header from '../Header/Header';
import SmallSite from './SmallSite';
import './Resources.css';
import {Link} from 'react-router-dom';
import supportData from './supportData';

const Support = () => {
  var site = supportData.map(s => (
    <SmallSite name={s.name} link={s.link} desc={s.desc}/>
  ));
  return (
    <div class='supportBody'>
      <link href="https://fonts.googleapis.com/css?family=Quicksand&display=swap" rel="stylesheet" />
      <Header pagename="resources"/>
      <h1 class="heading">Support</h1>
      <div id="support-sites">
        {site}
      </div>
    </div>
  );

}

export default Support;
