import React, {Component} from 'react';
import Header from '../Header/Header';
import Results from './Results';
import search from './search.png';
import {Stars} from '../Stars/Stars';
import './Map.css';
import firebase from '../firebase';
var L = require('leaflet');

function locationScore(location){
  return (Math.pow(location.rating,3)) * location.reviewCount;
}

function locationComparator(loc1,loc2){
  return locationScore(loc2) - locationScore(loc1);
}

class Map extends Component {
  constructor() {
    super();
    this.state = {
      map:null,
      points:[],
      active:0,
      addResourceOpen:false,
      signedIn:false,
      user:null,
      places: "",
      search: "",
      address: "",
      latitude:0,
      longitude:0,
      distance: 0,
      rating:0,
      formStyle: {
        display:'block',
      },
      buttonStyle: {
        bottom:"20.3%",
      }
    };
  }
  updateInput = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  }
  handleSubmit = e => {
    e.preventDefault();
    if (this.state.address !== "") {
      this.geocode(this.state.address);
    }
    this.state.map.remove();
    this.loadMap();
    this.getLocations();
    this.changeStyle();
  }
  componentWillMount(){
    //temporary!!!
    const db = firebase.firestore();
    db.collection('locations').get().then(
      (snapshot) => {
        snapshot.forEach(
          (doc) => {
            doc.ref.collection('reviews').get().then(
              (coll) => {
                doc.ref.update({reviewCount:coll.size});
              }
            )
          }
        )
      }
    )
    firebase.auth().onAuthStateChanged(user => {
      if(user){//when user is not 'falsy' i.e. null
        this.setState({
          signedIn:true,
          currentUser: user
        });
        //huuh
      }
    });
  }

  componentDidMount() {
    this.loadMap();
    //download data from firebase
    this.getLocations();
  }
  loadMap(){
    var mymap = L.map('resource-map').setView([40.73,-73.99],15);
    this.setState({map:mymap});
    L.tileLayer('https://api.tiles.mapbox.com/v4/{id}/{z}/{x}/{y}.png?access_token={accessToken}', {
      attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
      maxZoom: 18,
      id: 'mapbox.streets',
      accessToken: 'pk.eyJ1Ijoia2lya2lya2lya2lya2lyIiwiYSI6ImNpejJ3bTQwdDA0cnYyeW44cTk4bzN5ZjMifQ.L1ppV5qOfw9gJlZmB6VMeg'
    }).addTo(mymap);
  }
  render() {
    return (
      <div id="map-body">
        <link rel="stylesheet" href="https://unpkg.com/leaflet@1.5.1/dist/leaflet.css"
           integrity="sha512-xwE/Az9zrjBIphAcBb3F6JVqxf46+CDLwfLMHloNu6KEQCAWi6HcDUbeOfBIptF7tcCzusKFjFw2yuvEpDL9wQ=="
           crossorigin=""/>
        <Header pagename="map"/>
        <div id="body">
          <div id="left">
            <input maxlength="64" id="search-bar" type="text" name="search" placeholder="Search by name..." onChange = {this.updateInput} value = {this.state.search}/>
            <button id="search-submit" onClick = {this.handleSubmit}>
              <img id="search-glass" src={search} alt="" />
            </button>
            <Results list={this.state.points} active={this.state.active} parent={this}/>
          </div>
          <div id="resource-map" />
          <div id="filter-wrapper">
            <button class="open-button" onClick={this.changeStyle} style={this.state.buttonStyle}>^</button>
            <form id="filter-form" style={this.state.formStyle} >
              <table id="form-table">
                <tr>
                  <td>Services</td>
                  <td colspan="2">Address: (include city and state)</td>
                  <td>Distance (mi): </td>
                  <td>Min. Rating: </td>
                </tr>
                <tr>
                  <td id="places-wrapper">
                    <select class="select-css" id="places" name="places" onChange = {this.updateInput}>
                      <option value="All">All</option>
                      <option value="Shelter">Shelters</option>
                      <option value="Food">Food</option>
                      <option value="Shopping">Shopping</option>
                      <option value="Hospital">Hospitals</option>
                      <option value="School">Schools</option>
                      <option value="Center">Centers</option>
                      <option value="LGBTQ-owned Business">LGBTQ-owned Businesses</option>
                      <option value="Other">Other</option>
                    </select>
                  </td>
                  <td colspan="2" class="address"><input type="text" name="address" placeholder="30 Cooper Square, New York, NY 10003" onChange = {this.updateInput} value = {this.state.address}/></td>
                  <td><input class="distance" type="number" name="distance" min="1" max="250" onChange = {this.updateInput} value = {this.state.distance}/></td>
                  <td><Stars size="normal" starCount={this.state.rating} parent={this} /></td>
                </tr>
              </table>
              <div id="submit"><button id="submit-button" onClick = {this.handleSubmit}>Submit</button></div>
            </form>
          </div>
        </div>
      </div>
    );
  }

  changeStyle = e => {
    if (this.state.formStyle.display === 'none'){
      this.setState({
        formStyle: {
          display: 'block',
        },
        buttonStyle: {
          bottom:"20%",
        }
      });
    }
    else {
      this.setState({
        formStyle: {
          display: 'none'
        },
        buttonStyle: {
          bottom:"0px",
        }
      });
    }
  }

  handleStarUpdate(n){
    this.setState({
      rating:n
    });
  }
  openResourceAdd(){
    this.setState(
      {addResourceOpen:!this.state.addResourceOpen}
    )
  }
  geocode(address){
    const mapquestAPIKey = "JxG8xNRWNOZ6bxAt73Rx4Pb7RPhAxqyz";
    var queryURL = '//www.mapquestapi.com/geocoding/v1/address?key='+mapquestAPIKey+'&location='+address;
    this.getJSON(queryURL,(err,data) => this.getGeo(err,data));
  }
  getGeo(err,data){
    if(err !== null) {
      alert('geocoding failed: '+err);
    }else{
      // alert('geocode success');
      console.log(data);
      // alert('latitude: '+data.results[0].locations[0].latLng.lat);
      // alert('longitude: '+data.results[0].locations[0].latLng.lng);
      this.setState({
        latitude:data.results[0].locations[0].latLng.lat,
        longitude:data.results[0].locations[0].latLng.lng
      });
      //alert(this.state.latitude+' '+this.state.longitude);
    }
  }
  getJSON(url,callback){
    var xhr = new XMLHttpRequest();
    xhr.responseType = 'json';
    // alert(xhr.readyState);
    xhr.open('GET',url,true);
    xhr.onload = function() {
      // alert('loaded');
      var status = xhr.status;
      if(status===200){
        callback(null,xhr.response); //succeeded: status is 200
      }else{
        callback(status,xhr.response); //failed: status is smth else
      }
    }
    xhr.send();
  }
  findDistance(lat1, lon1, lat2, lon2) {
    if ((lat1 === lat2) && (lon1 === lon2)) {
  		return 0;
  	}
  	else {
  		var radlat1 = Math.PI * lat1/180;
  		var radlat2 = Math.PI * lat2/180;
  		var theta = lon1-lon2;
  		var radtheta = Math.PI * theta/180;
  		var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
  		if (dist > 1) {
  			dist = 1;
  		}
  		dist = Math.acos(dist);
  		dist = dist * 180/Math.PI;
  		dist = dist * 60 * 1.1515;
  		return dist;
  	}
  }
  filterLocations(doc) {
    var lat = doc.data().location.latitude;
    var lon = doc.data().location.longitude;

    this.setState({search:this.state.search.toLowerCase()}); //set state to lowercase

    if (this.state.distance > 0) {
      var distance = this.findDistance(lat, Math.abs(lon), this.state.latitude, Math.abs(this.state.longitude));
      if (distance > this.state.distance) {
        return false;
      }
    }
    if (this.state.rating > 0 && doc.data().rating < this.state.rating) {
      return false;
    }
    if (this.state.places === "All" || this.state.places === "") {
      return doc.data().searchname.includes(this.state.search);
    } else {
      /*
      if (doc.data().type === this.state.places) {
        return doc.data().name.includes(this.state.search);
      }
      */
      for (var i = 0; i < doc.data().type.length; i++) {
        if (doc.data().type[i] === this.state.places) {
          return doc.data().name.includes(this.state.search);
        }
      }
    }
  }
  getLocations(){
    //alert('getting locations');
    var locationList = [];
    //var markerList = [];
    const db = firebase.firestore();
    let locationRef = db.collection('locations');
    locationRef.get()
      .then(
        (snapshot) => {
          snapshot.forEach(
            (doc) => {
              if(doc.data().verified && this.filterLocations(doc)) {
                var locToAdd = doc.data();
                locToAdd.id = doc.id;
                locationList.push(locToAdd);
              }
            }
          );
          locationList.sort((loc1,loc2) => locationComparator(loc1,loc2))
          this.setState({
            points:locationList
          });
          var mymap = this.state.map;
          if(mymap===null) alert('yikes');
          else{
            for(var i=0;i<locationList.length;i++){
              var point = locationList[i];
              L.marker([point.location.latitude,point.location.longitude],{quID:i})
                .addTo(mymap).on('click',(e) => {this.pointSelected(e)});
            }
            if(this.state.latitude !== 0 && this.state.longitude !== 0){
              L.circleMarker([this.state.latitude,this.state.longitude],).addTo(mymap);
              L.circle([this.state.latitude,this.state.longitude],{radius:this.state.distance*1609,color:'#aaaaaa'}).addTo(mymap);
              mymap.setView([this.state.latitude,this.state.longitude]);
              mymap.setZoom(14);
            }
            // locationList.forEach(
            //   (point) => {
            //     var marker = L.marker([point.location.latitude,point.location.longitude]).addTo(mymap).on('click');
            //     marker.bindPopup('<b>'+point.name+'</b><br /><i>Rating: '+point.rating+' stars</i>');
            //   }
            // )
          }
        }
      );
    //yeet
  }
  pointSelected(e){
    this.resultSelected(e.sourceTarget.options.quID);
    global.location.hash = "result"+e.sourceTarget.options.quID;
    console.log(e.sourceTarget.options.link);
  }
  resultSelected(i){
    var point = this.state.points[i];
    // console.log(point);
    this.state.map.setView([point.location.latitude,point.location.longitude],16);
    this.setState({active:i});
    // console.log(e);
    // alert('item '+this.state.points[e.sourceTarget.options.quID].name+' clicked');
  }
}

export default Map;
