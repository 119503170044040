const hotlines = [{
  "name": "The Trevor Project Lifeline",
  "desc": "Trained counselors are here to support you 24/7. If you are a young person in crisis, feeling suicidal, or in need of a safe and judgment-free place to talk, call the TrevorLifeline now.",
  "num": "1-866-488-7386",
  "link": "https://www.thetrevorproject.org/get-help-now/",
  "hours": "Open 24 hours"
},
{
  "name": "The Trevor Project Chat",
  "link": "https://www.thetrevorproject.org/get-help-now/",
  "num": "Click link above to start",
  "hours": "7 days a week, 3 pm to 9 pm ET",
  "desc": "A free, confidential and secure instant messaging service that provides live help to LGBTQ youth. It is easily accessible through a computer."
},
{
  "name": "The Trevor Project Text",
  "num": "Text START to 678678",
  "link": "https://www.thetrevorproject.org/get-help-now/",
  "hours": "Thurs and Fri, 4 pm to 8 pm ET",
  "desc": "A confidential and secure resource that provides live help for LGBTQ youth with a trained specialist, over text messages."
},
{
  "name": "Gay, Lesbian, Bisexual and Transgender National Hotline",
  "num": "1-888-843-4564",
  "link": "https://www.glbthotline.org/national-hotline.html",
  "hours": "Mon - Fri, 4 pm to 12 am ET and Sat, 12 pm to 5 pm ET",
  "desc":"A free and confidential service through telephone, online private one-to-one chat and email peer-support that provides factual information and local resources for cities and towns across the United States. Callers of all ages can discuss coming-out issues, gender identity, relationship concerns, bullying, workplace issues, HIV/AIDS anxiety and safer-sex information, and lots more!"
},
{
  "name": "GLBT National Youth Talkline",
  "num": "1-800-246-7743",
  "link": "https://www.glbthotline.org/talkline.html",
  "hours": "Mon - Fri, 4 pm to 12 am ET and Sat, 12 pm to 5 pm ET",
  "desc":"A free and confidential service through telephone, online private one-to-one chat and email peer-support that provides factual information and local resources for cities and towns across the United States. Teens and young adults up to age 25 can discuss coming-out issues, relationship concerns, parent issues, school problems, HIV/AIDS anxiety and safer-sex information, and lots more!"
},
{
  "name": "Trans Lifeline",
  "desc": "A 24/7 hotline available in the U.S. and Canada staffed by transgender people for transgender people. Trans Lifeline is primarily for transgender people in a crisis, from struggling with gender identity to thoughts of self-harm.",
  "num": "1-877-565-8860",
  "link": "https://www.translifeline.org/",
  "hours": "Open 24 hours"
},
{
  "name": "National Runaway Safeline",
  "link": "https://www.1800runaway.org",
  "desc": "NRS serves as the national communication system for runaway and homeless youth.",
  "num": "Call 1-800-RUNAWAY or text 66008",
  "hours": "Open 24 hours"
},
{
  "name": "National Suicide Prevention Lifeline",
  "desc": "The Lifeline provides 24/7, free and confidential support for people in distress, prevention and crisis resources for you or your loved ones, and best practices for professionals.",
  "num": "1-800-273-8255",
  "link": "https://suicidepreventionlifeline.org",
  "hours": "Open 24 hours"
},
{
  "name": "U.S. National Domestic Violence Hotline:",
  "desc": "Confidential and free of cost, the National Domestic Violence Hotline provides lifesaving tools and immediate support to enable victims to find safety and live lives free of abuse. Highly trained, experienced advocates offer compassionate support, crisis intervention information and referral services in over 170 languages. The hotline is available for both English and Spanish speakers",
  "num": "Call (800) 799-7233 or text (800) 787-3224",
  "link": "https://www.thehotline.org",
  "hours": "Open 24 hours"
},
{
  "name": "Pride Institute",
  "desc": "Chemical dependency/mental health referral and information hotline for the LGBTQ community.",
  "num": "(800) 547-7433",
  "link": "https://www.prideinstitute.net",
  "hours": "Open 24 hours"
},
{
  "name": "Fenway Health Helpline",
  "desc": "Anonymous and confidential phone lines that offer lesbian, gay, bisexual, transgender, queer and questioning adults and young people a “safe place” to call for information, referrals, and support. Trained volunteers can help you with coming out as well as locating LGBT groups and services in your local area. Volunteers can also offer support and guidance around common issues including safer sex and relationships and HIV/AIDS.",
  "num": "(888) 340-4528",
  "link": "https://fenwayhealth.org/care/wellness-resources/help-lines/",
  "hours": "Monday – Saturday, 6:00 – 11:00 pm"
},
{
  "name": "Fenway Peer Listening Line",
  "desc": "Anonymous and confidential phone lines that offer lesbian, gay, bisexual, transgender, queer and questioning adults and young people a “safe place” to call for information, referrals, and support. Trained volunteers, between 16 and 25 years old, can help you with coming out as well as locating LGBT groups and services in your local area. Volunteers can also offer support and guidance around common issues including safer sex and relationships and HIV/AIDS.",
  "num": "(800) 399-PEER",
  "link": "https://fenwayhealth.org/care/wellness-resources/help-lines/",
  "hours": "Monday – Saturday, 5:00 – 10:00 pm"
}
];

export default hotlines;
