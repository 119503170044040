import React, {Component} from 'react';
import { Route, Redirect } from 'react-router'
import firebase from '../firebase';
import Header from '../Header/Header';
import ProfilePage from './ProfilePage';
import './ProfilePage.css';

class PasswordReset extends Component{
  constructor(){
    super();
    this.state = {
      signedIn:false,
      currentUser:null,
      newPassword:"",
      currentPassword:"",
      confirm:""
    }
  }

  componentDidMount(){
    firebase.auth().onAuthStateChanged(user => {
      if(user){//when user is not 'falsy' i.e. null
        this.setState({
          signedIn:true,
          currentUser: user,
      });
    }
  });
  }

  render(){
    if (this.state.signedIn && this.state.currentUser.emailVerified){
      var user = this.state.currentUser;
      return(
        <div>
          <Header />
          <h1>Your Account Settings</h1>
          <hr /><br />
          <div class="page">
            <div class="sidenav">
              <a href="/profile/info">Your Profile</a>
              <a href="/profile/email">Email Reset</a>
              <a class="selected" href="/profile/password">Password Reset</a>
            </div>
            <div class="main">
              <form onSubmit={this.handleSubmit}><br />
                Current Password:
                <input maxlength="64" name="currentPassword" type="password" value={this.state.currentPassword} onChange={this.updateInput}>
                </input><br /><br />
                New Password:
                <input maxlength="64" name="newPassword" type="password" value={this.state.newPassword} onChange={this.updateInput}>
                </input><br /><br />
                Confirm New Password:
                <input maxlength="64" name="confirm" type="password" value={this.state.confirm} onChange={this.updateInput}>
                </input><br /><br />
                <button onClick>Submit</button>
              </form>
            </div>
            <div class="submit">
            </div>
          </div>
        </div>
      )
    }
    else if (this.state.signedIn){
      return (
        <div>
          <Header />
          <h1>Your Account Settings</h1>
          <hr /><br />
          <div class="page">
            <div class="sidenav">
              <a href="/profile/info">Your Profile</a>
              <a href="/profile/email">Email Reset</a>
              <a class="selected" href="/profile/password">Password Reset</a>
            </div>
            <div class="main">
            <br /><br /><br /><br /><br /><br />
              <h3 align="center">Please verify your email to access this feature.</h3>
            </div>
            <div class="edit">
            </div>
          </div>
        </div>
      )
    }
    else {
    return(
      <div>
        <Header />
        <h1>Your Account Settings</h1>
        <hr /><br />
        <div class="page">
          <div class="sidenav">
            <a href="/profile/info">Your Profile</a>
            <a href="/profile/email">Email Reset</a>
            <a class="selected" href="/profile/password">Password Reset</a>
          </div>
          <div class="main">
          </div>
          <div class="edit">
          </div>
        </div>
      </div>
      )
    }
}

  updateInput = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  handleSubmit = e => {
    e.preventDefault();

    if (this.state.confirm === this.state.newPassword){
      this.reauthenticate(this.state.currentPassword).then(() => {
        var user = firebase.auth().currentUser;
        user.updatePassword(this.state.newPassword).then(() => {
          this.setState({
            newPassword:"",
            currentPassword:"",
            confirm:""
          });
          alert('Password was successfully updated!');
        }).catch((error) => {
          alert(error.code);
        });
      }).catch((error) => {
        alert(error.code);
      });
    }
    else {
      alert("Passwords don't match!");
    }

  }

  reauthenticate = (currentPassword) => {
    var user = firebase.auth().currentUser;
    var cred = firebase.auth.EmailAuthProvider.credential(user.email, currentPassword);
    return user.reauthenticateWithCredential(cred);
  }
}

export default PasswordReset;
