import React, {Component} from 'react';
import Header from '../Header/Header';
import './Education.css';
import {Link} from 'react-router-dom';

const Education = () => {
  return (
    <div class='educationBody'>
      <link href="https://fonts.googleapis.com/css?family=Quicksand&display=swap" rel="stylesheet" />
      <Header pagename="education"/>
      <h1 id="ed-title">Education</h1><br />
      <table id="ed-categories">
        <tr>
          <td class="divider"><a href="#language">Language</a></td>
          <td class="divider"><a href="#allyship">Allyship</a></td>
          <td class="divider"><a href="#media">Representation</a></td>
          <td class="divider"><a href="#sex-ed">Sex Ed</a></td>
          <td class="divider" id="lastone"><a href="#history">History</a></td>
        </tr>
        <tr>
          <td>
          <ul>
            <li><a class="small_links" href="#gender">Gender & Sex</a></li>
            <li><a class="small_links" href="#sexuality">Sexuality </a></li>
            <li><a class="small_links" href="#romantic">Romantic Identities </a></li>
            <li><a class="small_links" href="#terminology">Terminology</a></li>
          </ul>
          </td>
          <td>
            <ul>
              <li><a class="small_links" href="#startingpts">Starting Points</a></li>
              <li><a class="small_links" href="#parents">For Parents</a></li>
              <li><a class="small_links" href="#trans">For Trans People</a></li>
            </ul>
          </td>
          <td>
            <ul>
              <li><a class="small_links" href="#importance">Importance</a></li>
              <li><a class="small_links" href="#actors">Actors</a></li>
            </ul>
          </td>
          <td>
            <ul>
              <li><a class="small_links" href="#teens">Teenagers</a></li>
              <li><a class="small_links" href="#parents-ed">Parents</a></li>
              <li><a class="small_links" href="#teachers">Teachers</a></li>
            </ul>
          </td>
          <td></td>
        </tr>
      </table><br/>

      <div class="card" id="language">
        <h1>Language</h1><hr/><br/><br/><br/><br/>
        <ul>
          <div id="gender">
            <h4>Gender</h4>
            <h5> Your gender identity is how you feel inside and how you express those feelings.  Clothing, appearance, and behaviors can all be ways to express your gender identity. </h5>
            <div>  <b>Agender</b> – A person who sees themself as not having a gender. Some agender-identified people see themself as being gender neutral, rather than not having any gender, but in any case do not identify with a gender. </div>
            <div> <b>Bigender</b> - A person who fluctuates between traditionally “woman” and “man” gender-based behavior and identities, identifying with two genders (or sometimes identifying with either man or woman, as well as a third, different gender). </div>
            <div> <b>Cisgender</b> – Refers to people whose Gender identity aligns with their assigned sex at birth  </div>
            <div> <b>Feminine-of-center; masculine-of-center</b> - Phrases that indicate a range in terms of gender identity and expression for people who present, understand themselves, and/or relate to others in a generally more feminine/masculine way, but don’t necessarily identify as women or men. Feminine-of-center individuals may also identify as “femme,” “submissive,” “transfeminine,” etc.; masculine-of-center individuals may also often identify as “butch,” “stud,” “aggressive,” “boi,” “transmasculine,” etc. </div>
            <div>  <b>Genderfluid</b> – People who have a gender or genders that change. Genderfluid people move between genders, experiencing their gender as something dynamic and changing, rather than static. </div>
            <div> <b>Gender non-conforming</b> - A gender expression descriptor that indicates a non-traditional gender presentation (masculine woman or feminine man). 2 adj. : a gender identity label that indicates a person who identifies outside of the gender binary. Often abbreviated as “GNC.”</div>
            <div> <b>Gender normative / gender straight</b> - Someone whose gender presentation, whether by nature or by choice, aligns with society’s gender-based expectations. </div>
            <div>  <b>Genderqueer</b> – An umbrella term to describe someone who doesn’t identify with conventional gender identities, roles, expression and/or expectations. For some, genderqueer is a Non-binary identification, and for others it is not. </div>
            <div> <b>Gender variant</b> - Someone who either by nature or by choice does not conform to gender-based expectations of society (e.g. transgender, transsexual, intersex, genderqueer, cross-dresser, etc). </div>
            <div> <b>Third gender</b> - A person who does not identify with either man or woman, but identifies with another gender. This gender category is used by societies that recognise three or more genders, both contemporary and historic, and is also a conceptual term meaning different things to different people who use it, as a way to move beyond the gender binary. </div>
            <div> <b>Two-spirit</b> - An umbrella term traditionally within Native American communities to recognize individuals who possess qualities or fulfill roles of both feminine and masculine genders. </div>
            <div>  <b>Transgender</b> – Sometimes this term is used broadly as an umbrella term to describe anyone whose gender identity differs from their assigned sex. It can also be used more narrowly as a gender identity that reflects a binary gender identity that is “opposite” or “across from” the sex they were assigned at birth. </div>
            <br/><i>For a more encompassing list of identities, <a href="https://lgbtqia.ucdavis.edu/educated/glossary">click here.</a></i><br />
            <div> <i>For more information, <a href="https://www.glsen.org/sites/default/files/Gender%20Terminology%20Guide.pdf">click here.</a></i><br />  </div>
          </div>
          <div id="sex">
            <h4>Sex</h4>
            <h5> Sex is a label — male or female — that you’re assigned by a doctor at birth based on the genitals you’re born with and the chromosomes you have. It goes on your birth certificate. </h5>
            <div> <b>Male</b>  </div>
            <div> <b>Intersex</b> – Term for a combination of chromosomes, gonads, hormones, internal sex organs, and genitals that differs from the two expected patterns of male or female. Though formerly known as hermaphrodite (or hermaphroditic),  these terms are now outdated and derogatory.  </div>
            <div>  <b>Female</b> </div>
          </div>
      <div id="sexuality&romantic">
        <div id="sexuality">
          <h4>Sexuality</h4>
          <h5> For many people, gender or sex have the biggest impact on how sexually attractive they find someone, but this doesn't mean that people who are attracted to a particular gender are attracted to all people of that gender or always unattracted to people of other genders. </h5>
          <div id="sexualityList">
        <div> <b>Asexual (Ace):</b> An individual who does not experience sexual attraction or experiences such a low level of sexual attraction that they do not consider it to be notable. Like any other sexuality, asexuality is diverse, and each individual may experience asexuality differently. Asexuality exists on a spectrum and includes people who experience no sexual attraction or have any desire for sex to those who experience low levels and only after significant amounts of time.</div>
        <div>  <b>Grey Asexual (Grey A):</b> An individual who identifies as Grey-A typically does not normally experience sexual attraction but may experience sexual attraction sometimes, experience sexual attraction but has a low sex-drive, experience sexual attraction and has a sex-drive but not enough to wish to act on them, or it may be someone who can enjoy and even desire sex, but only under a very specific and limited circumstances.</div>
        <div> <b>Androsexual / androphilic:</b> being primarily sexually, romantically and/or emotionally attracted to men, males, and/or masculinity.</div>
        <div>  <b>Bisexual:</b> Most commonly, bisexuality is seen as an emotional and/or sexual attraction to two genders. This definition includes the fact that some individuals who identify as bisexual are sexually and/or emotionally attracted to more than one gender but only form relationships with one. Another commonly used definition is a sexual attraction towards the same gender, and gender(s) different than your own. This attraction does not have to be equally split or indicate a level of interest that is the same across the genders or sexes an individual may be attracted to.</div>
        <div>  <b>Demi-sexual:</b> Someone who identifies as Demi-sexual does not experience sexual attraction until they form a strong emotional connection with someone. In general, people who identify as Demi-sexual are not sexually attracted to anyone of any gender, but if an emotional connection is formed with someone else, they may experience sexual attraction towards the specific partner(s).</div>
        <div>  <b>Gay:</b> This term can be used as an umbrella for anyone who is sexually or romantically attracted to someone of the same gender. The second definition is used to exclusively refer to someone who is male-identified, who is romantically or sexually attracted to other male-identified individuals.</div>
        <div>  <b>Heterosexuality:</b> This is a sexual attraction to the “opposing” sex/gender. Typically this means a female/women attracted to male/men, and vice versa. Also known as straight.</div>
        <div>  <b>Lesbian:</b> A female-identified person who is sexually and/or romantically attracted to other female-identified individuals.</div>
        <div>  <b>Pansexual:</b> Pansexuality is a sexual orientation used to describe an individual who feels they are sexually and/or romantically attracted to all genders, based on an individual’s personality.</div>
        <div>  <b>Polyamory/Polyamorous:</b> refers to the practice of, desire to, or orientation towards having ethically, honest, consensually non-monogamous relationships (i.e. relationships that may include multiple partners). This may include open relationships, polyfidelity (which involves more than two people being in romantic and/or sexual relationships which is not open to additional partners), amongst many other set ups. Some poly(amorous) people have a “primary” relationship or relationship(s) and then “secondary” relationship(s) which may indicate different allocations of resources, time, or priority.</div>
        <div>  <b>Queer:</b> This is an umbrella term for anyone who is not heterosexual, gender-binary and/or heteronormative.</div>
        <div>  <b>Questioning:</b> an individual who is unsure about or is exploring their own sexual orientation or gender identity. </div>
        <br/><i>For a more encompassing list of identities, <a href="https://lgbtqia.ucdavis.edu/educated/glossary">click here.</a></i><br />
          </div>
        </div>
        <div id="romantic">
          <h4>Romantic Identities</h4>
          <h5> The desire for intimate and emotional relationships with people of particular genders or sexes. It's about who we feel affection for and may include who we seek out to build a life or family with. </h5>
          <div id="romanticList">
        <div>  <b>Aromantic:</b> An individual who experiences a lack of romantic attraction or a lack of interest in forming romantic relationships.</div>
        <div>  <b>Demi-romantic:</b> Someone with a lack of romantic attraction, desire or need for an intimate or physical nature. This differs from the Aromantic identity, since those who are Demi-romantic have the ability to develop feelings of romantic attraction for someone only after getting to know them, and understand them as a person, usually built out of an initial very close friendship. </div>
        <div>  <b>Polyamory/Polyamorous:</b> refers to the practice of, desire to, or orientation towards having ethically, honest, consensually non-monogamous relationships (i.e. relationships that may include multiple partners). This may include open relationships, polyfidelity (which involves more than two people being in romantic and/or sexual relationships which is not open to additional partners), amongst many other set ups. Some poly(amorous) people have a “primary” relationship or relationship(s) and then “secondary” relationship(s) which may indicate different allocations of resources, time, or priority.</div>
        <div>  <b>Queer:</b> This is an umbrella term for anyone who is not heterosexual, gender-binary and/or heteronormative.</div>
        <div>  <b>Questioning:</b> an individual who is unsure about or is exploring their own sexual orientation or gender identity. </div>
        <br/><i>For a more encompassing list of identities, <a href="https://lgbtqia.ucdavis.edu/educated/glossary">click here.</a></i><br />

          </div>
        </div>
      </div>

      <h4 id="terminology"> GLAAD's Guide to Terminology for Allies </h4>
        <div> For the language an ally should use and avoid when talking about the LGBTQ+ community, equality for the LGBTQ+ community, marriage for same-sex couples, non-descrimination laws, open military service, and parenting and adoption <a href="http://www.glaad.org/sites/default/files/allys-guide-to-terminology_1.pdf">click here.</a><br /> </div>
      </ul>
      </div>

      <div class="card" id="allyship">
        <h1>Allyship</h1><hr/><br/><br/><br/><br/>
        <h5> Information adapted from PFLAG's blog  "We Are All Allies", Advocates For Youth's  "Are You an Askable Parent", Healthychildren.org, Transwhat's "Guide to Being An Ally" </h5>
        <h3> What Is An Ally? </h3>
        <div> An ally to the LGBTQ+ communtiy is someone who actively supports the LGBTQ+ communtiy. Support can come in many forms. The first step an ally can take is to educate themselves. The best thing an ally can do is openly and actively support the LGBTQ+ community. Whether this manifests in marching at Pride or supporting a friend who identifies as LGBTQ+, allies can really help the LGBTQ+ community. </div>
        <div id = 'allyship-text'>
        <h3 id="startingpts"> Starting Points </h3>
        <ol>
        <li> Learn. </li>
        <div> In guide to being a straight ally, it’s made clear that allies are people who recognize that, while they don’t know all that can be known about the issues or experiences of people to whom they want to be an ally, they do want to learn and understand more. So make it a point to learn. </div>
        <li> Listen. </li>
        <div> When someone takes the time to share with you their lived experience, hear them. Really hear them. Instead of using it as an opportunity to explain why you’re not homophobic or transphobic (or anything else), or to explain that you know “exactly” how they feel, use it as an opportunity to stop talking and start taking in information. Also, be grateful that this person is choosing to educate you and bear their hearts to you. It takes real vulnerability and sacrifice to educate people especially if that person is telling you about their past traumas or explaining why they deserve to have basic human rights. And here is something important to bear in mind as you do: You are hearing the experience of one person, whose experience is not the experience of an entire group of people, nor is it that one person’s responsibility to speak out as a representative of a group to which they belong. A good ally bears this in mind, and simply uses what they learn of this unique experience to further inform what they are starting to learn. </div>
        <li> Act. </li>
        <div> This is where your privilege as an ally can make a difference--it can still be challenging to stand up, but don’t let the challenge deter you.
        Whether it’s asking someone not to use inappropriate words, speaking with a friend about poorly thought out behaviour, making sure the needs of others are considered when making decisions, or speaking up when an inappropriate joke is shared, each of these can make a big difference. </div>
        <li> Be ok with being uncomfortable. </li>
        <div>Sometimes, the hardest part of being an ally can be sitting in discomfort: whether it’s discomfort with new information, discomfort with hearing you may have made a mistake--in the present or in the past--in your ally attempts, or even discomfort in being an ally, with the privileges that sometimes come along with that designation.  Yes, it’s hard to be uncomfortable, but it’s a wonderful opportunity to go within and explore where you stand and what you believe, to reflect and review. If you can sit within the discomfort, you’ll find that there’s an enormous amount of personal growth that can happen in that place. </div>
        </ol>
        <h3 id="parents"> Allyship as a Parent </h3>
        <div> As a parent, the most important thing is that your kids know you will love and accept them no matter what. After that, you can focus on being supportive and 'askable'. </div>
        <ol>
        <li> Acquire a broad foundation of factual information from reliable sources. Remember that sexuality is a much larger topic than sexual intercourse. It includes biology and gender, of course, but it also includes emotions, intimacy, caring, sharing, and loving, attitudes, flirtation, and sexual orientation as well as reproduction and sexual intercourse. </li>
        <li> Learn and use the correct terms for body parts and functions. If you have difficulty saying some words without embarrassment, practice saying these words, in private and with a mirror, until you are as comfortable with them as with non-sexual words. For example, you want to be able to say “penis” as easily as you say “elbow.” </li>
        <li> Think through your own feelings and values about love and sex. Include your childhood memories, your first infatuation, your values, and how you feel about current sex-related issues, such as contraceptives, reproductive rights, and equality with regard to sex, gender, and sexual orientation. You must be aware of how you feel before you can effectively talk with youth. </li>
        <li> Talk with your child. Listen more than you speak. Make sure you and your child have open, two-way communication—as it forms the basis for a positive relationship between you and your child. Only by listening to each other can you understand one another, especially regarding love and sexuality, for adults and youth often perceive these things differently. </li>
        <li> Don’t worry about— </li>
        <ul>
        <li> Not knowing the right words or the right answers - speaking about gender or sexuality is never easy and won't ever be perfect, instead your child will just be grateful you tried your best </li>
        <li>  Being out of it in the eyes of their young people. Youth have that with their peers. From you, they want to know what you believe, who you are, and how you feel.</li>
        <li> Being embarrassed. Your kids will feel embarrassed, too. That’s okay, because love and many aspects of sexuality, including sexual intercourse, are highly personal. Young people understand this. </li>
        <li> Deciding which parent should have this talk. Any loving parent or caregiver can be an effective sex educator for his/her children. </li>
        </ul>
        </ol>
        <div> Talking to Young Children </div>
        <ul>
        <li> Remember that if someone is old enough to ask, she/he is old enough to hear the correct answer and to learn the correct word(s). </li>
        <li> Be sure you understand what a young child is asking. Check back. For example, you might say, “I’m not certain that I understand exactly what you are asking. Are you asking if it’s okay to do this or why people do this?” What you don’t want is to launch into a long explanation that doesn’t answer the child’s question. </li>
        <li> Answer the question when it is asked. It is usually better to risk embarrassing a few adults (at the supermarket, for example) than to embarrass your child or to waste a teachable moment. Besides, your child would usually prefer it if you answer right then and softly. If you cannot answer at the time, assure the child that you are glad he/she asked and set a time when you will answer fully. “I’m glad you asked that. Let’s talk about it on the way home.” </li>
        <li> Answer slightly above the level you think your child will understand, both because you may be underestimating him/her and because it will create an opening for future questions. But, don’t forget that you are talking with a young child. </li>
        </ul>
        <div> Talking to Teens </div>
        <ul>
        <li> Recall how you felt when you were a teen. Remember that adolescence is a difficult time. One moment, a teen is striving for separate identity and independence, and the next moment urgently needs an adult’s support.</li>
        <li> Remember that teens want mutually respectful conversations. Avoid dictating. Share your feelings, values, and attitudes and listen to and learn about theirs. Remember that you cannot dictate anyone else’s feelings, attitudes, or values. </li>
        <li> Don’t assume that a teen is sexually experienced or inexperienced, knowledgeable or naive. Listen carefully to what your teen is saying and/or asking. Respond to the teen’s actual or tacit question, not to your own fears or worries. </li>
        <li> Don’t underestimate your teen’s ability to weigh the advantages and disadvantages of various options. Teens have values, and they are capable of making mature, responsible decisions, especially when they have all the needed facts and the opportunity to discuss options with a supportive adult. If you give your teen misinformation she/he may lose trust in you, just as he/she will trust you if you are a consistent source of clear and accurate information. Of course, a teen’s decisions may be different from ones you would make; but that goes with the territory. </li>
        </ul>
        <div> If Your Child Comes Out To You </div>
        <ul>
        <li> Finding out your son or daughter is gay, lesbian, or bisexual can be difficult. Parents often feel guilty. They ask themselves questions like, "Did I do anything to cause this?" "Should we have done something differently when he was a child?" "Is it my fault?" Questions like these are common, but do not help.</li>
        <li> Rejecting your child also is not a good response. It may have been very difficult for your child to come to terms with her or his sexuality. But it could be devastating if you reject her or him at the same time. Your child needs you very much!</li>
        <li>So take a deep breath and think. Take a little time to come to grips with your child's news. You may need to readjust your dreams for your child's future. You may have to deal with your own negative stereotypes of gay, lesbian, and bisexual people. But you must not reject your teenager. He or she is still your child and needs your love and support. </li>
        <li>Your teen did not choose to be gay, lesbian, or bisexual. Accept her or him and be there to help with any problems that arise. Your pediatrician may be able to help you with this new challenge or suggest a referral for counseling. You also may find it helpful to talk with other parents whose children are lesbian, gay, or bisexual. Check "Resources" at the end of this article for information about support groups for parents. </li>

        </ul>
        <h3 id="trans"> Allyship to Trans People - a guide by TransWhat</h3>
        <div> Bare Minimums </div>
        <ul>
        <li> Call people by their preferred name, pronouns, and label. Always. Even if you're angry with them, even if they're total jerks, even if they're using gender-neutral pronouns that "sound weird" or "are hard to remember." Yes, even when they're not around to hear. It's a respect thing. </li>
        <li> If you've met the person after transition: don't ask to see pictures from "before," or ask about their previous name, or otherwise quiz them on topics that are likely offensive/painful. </li>
        <li> Don't try to compliment people by telling them that they look like a "real [gender]," or that you "never would have known." </li>
        <li>  Don't make comments about the person's gender presentation that you wouldn't make to someone who was assigned that same gender at birth. Critiquing a trans woman's makeup in detail, or offering a trans man suggestions on how to walk "like a guy," is as rude as it would be if you were talking to a cis person. </li>
        <li> Do not inform any third party that your sibling/parent/partner/whomever is trans without the trans person's express permission, gotten in advance. </li>
        <li> Don't describe past situations by saying "When [person] was a [gender]. . ." </li>
        <li> Don't ever describe someone as a member of the wrong gender, even in a way that's superficially nice. "But you're so handsome as a man!" is unacceptable, as is "You were a lovely little girl." </li>
        <li> Words that you shouldn't ever use: "tranny," "shemale," "he-she," "shim." Seriously. Even if your other trans friend told you it was okay. Just don't say it. </li>
        <li> Don't make comments that fetishize trans people. "I love trans guys — they're so hot!" is pretty belittling; so is "People like you are so exotic." These kinds of statements reduce trans people to sex objects, as though we exist just to be that "exotic" kink or turn-on. </li>
        <li> Don't ever ever inquire about the state of someone's genitals, about whether they're having surgery, or about how they have sex. </li>
        <li> Don't make assumptions about someone's sexual orientation. Some trans men are gay or bi, or asexual; likewise with some trans women. Genderqueer folks have sexual attractions that come in all stripes. </li>
        <li> If someone's gender is ambiguous, resist asking "What are you?" flat-out; though some people don't mind or even relish it, for many it's simply intrusive. Instead, try to pick up on the person's identification through context. If you really don't know, and really need to talk about the person in a gendered way, ask "What pronouns do you prefer?" or "How should I refer to you, gender-wise?" (Do this very politely, and in private if you can.) You don't need to know every detail about the person's identity — you only need the information that will allow you to speak to and about them respectfully. </li>
        </ul>
        <div> More Complex Accomodations </div>
        <ul>
        <li> Yes, it is certainly difficult to adapt to thinking of a person in a new way, particularly if you've known that person all your life. A period of discomfort, or even mourning, is not uncommon. But I challenge you to try to work through that — to understand that trans experiences are usually much harder for the trans people themselves — and to work earnestly on understanding, rather than becoming bogged down in regret. </li>
        <li> Many aspects of these concepts can be confusing or difficult at first. (Maybe your trans son is still in a relationship with a lesbian . . . why? Maybe your trans sister chooses not to have surgery, though she could afford it . . . why?) However, most trans people do not wish to serve as constant educators; being asked to justify your choices, some of which are so instinctive that they're beyond words, is tiring and draining for everyone. Be sure to think over your questions carefully, seeing if you can answer them with your own common sense, before you ask the trans individuals themselves. </li>
        <li> If you're in charge of a public bathroom of some sort — in a store, perhaps, or a university building or a workplace — you may wish to label it as unisex or "family." Some, though not all, trans people are not comfortable or safe in either exclusively-men's or exclusively-women's facilities (maybe they're pre-transition, maybe they present as androgynous and don't want to be hassled). It's not a big deal to put up a new sign, and it makes sense for reasons beyond trans issues; young children, for instance, are more easily able to enter unisex bathrooms with their other-gender parents. Keep in mind that a gender-neutral bathroom should not be used to segregate trans from cis, but rather exist as a voluntary option. It'd be inappropriate to say "Trans women in the genderless lavatory, cis women in the women's lavatory"; that sort of phrasing implies that trans women are different or unreal. </li>
        <li> Refer to "all genders" or "any gender" (plural) rather than "both genders" or "either gender" (dual). Gender is not a salt-and-pepper set, with only two condiments on the table, if you want to think of it that way. It's a much larger sort of system, including the possibilities of "male" and "female," but not excluding anyone else either. </li>
        <li> Occasionally — when you're on the subway, maybe, or driving past a pedestrian — you'll see someone whose gender you can't decipher at first glance. A total stranger, not someone whose identity you actually might need to know. Your instinct is likely to take a closer look at the person, closer than you normally would, and try to figure out what gender they are. Avoid this, if you can, or at least stop yourself consciously in the midst of doing it. The person may not know the wiser, unless you're outright staring, but this is an example of an invasive and transphobic behavior. It's not the King of Indiscretions, but it needs to be treated seriously: you'd feel insulted if you were scrutinized in such a way ("is she or isn't she?") and so will most other people. </li>
        <li> Trans people are not freaks. The label of "freakishness" can go either way: people may see it as something repugnant, creepy, or against nature — or they may consider it exotic, or especially erotic, or radical. But transness is none of these things. It can't be so easily characterized by that kind of shallow stereotype. It isn't something super-special to be considered "cool" and "fascinating," and it isn't something super-gross or weird or barbaric. It's just a Thing, a fact of some people's existence, and when you assign it a moral meaning the actual facts can get lost in the theory. Try to level out your thinking on trans issues, realizing that it's more complex than either an "awful curse" or a "special gift," but just is. ... Some people, individually, will choose to embrace a freak identity. But that has to do with their individual journeys, rather than encompassing all of what trans means to everyone else. </li>
        </ul>
      </div>
      </div>

      <div class="card" id="media">
        <h1>Media Representation</h1><hr/><br/><br/><br/><br/>
        <div id = 'media-text'>
            <h3 id="importance"> Why it&apos;s important for the LGBTQ+ community to be represented </h3>
            <div> It’s important for the media to represent the LGBTQ+ community and not erase our lives so people who are not a part of the community know we exist. In trying to rectify layers and layers of systemic oppression, the first step is acknowledging our existence.</div>
            <div> The increase in LGBTQ+ representation in mainstream media since the 1990s has also coincided with the improvement in public perception of the LGBTQ+ community. With gay relationships being normalized in shows like Modern Family or by Ellen Degeneres coming out on national television, the younger generation is increasingly accepting of LGBTQ+ relationships. </div>
            <div> Whether intentionally or not, the media also has the power to influence how the public sees the LGBTQ+ community. However, with this representation comes great responsibility to accurately  depict LGBTQ+ lives. When done right, the LGBTQ+ community has a voice. However, when reduced to stereotypes and punchlines, the media can have seriously damaging effects on the LGBTQ+ community. For instance, when hypersexualizing gay relationships in TV and movies, the media reduces gay relationships to a purely sexual transaction. This stigmitizes gay relationships with real world consequences; gay marriage is legal in only 23 countries. </div>
            <div> Not only that, the media has the power to influence how members of the LGBTQ+ community sees itself. For instance, even if LGBTQ+ characters exist in mainstream media, if they&pos;re only white, cisgender, gay men, the rest of the LGBTQ+ community is rendered invisible. The message to the rest of the LGBTQ+ community then is "if you're not a cis, white, gay male  then you're not palatable to the rest of America; so in our eyes, you don't exist and you don't matter". In addition, with the hypersexualization of LGBTQ+ relationships, LGBTQ+ people are taught that they can only exist as sexual objects, incapable of love and emotion, which is incredibly damaging. </div>
            <h3 id="actors"> Why it&apos;s important for the LGBTQ+ community to be represented by LGBTQ+ actors </h3>
            <div> However, even then the number of LGBTQ+ roles in Hollywood is disproportional to the number of people who identify as LGBTQ+. Not only does this mean that LGBTQ+ people don’t see themselves in the media as often as their counterparts but it also means that LGBTQ+ actors, who are already less likely to be cast, have harder times trying to make a living. Then there’s the issue of actors who don’t identify as LGBTQ+ taking the few LGBTQ+ roles that exist in Hollywood. If there were a proportional number of LGBTQ+ roles and just descriptions of the LGBTQ+ community in Hollywood, LGBTQ+ actors would not have to fight to represent their community. However, as long as that is not the case, the few LGBTQ+ roles that exit should be played by LGBTQ+ actors.
            <div> Let's take Scarlett Johansson's controversial casting as a trans man  in "Rub & Tug". Trans actors already have the odds stacked against them. Giving trans actors the few trans roles that exist in Hollywood is the least we can do to counteract the effects of transphobia in the acting industry. Also the excuse that cis actors have been portraying trans roles and lauded for their performance ignores the fact that even in the past, the trans community has objected to cis actors portraying their stories. In saying this, Scarlett Johansson is really only upset that know people listen to the trans community&apos;s concerns. Lastly, her comment that she should be able to portray "any person, tree, or animal" is incredibly  ignorant considering the history of the dehumanization of trans people, often as a prelude to violence. </div> </div>
            <br/>
            <div> <i>For a list of LGBTQ+ books, <a href="https://www.glbthotline.org/booklist.html">click here.</a><br /> </i></div>
            <div> <i>For a list of LGBTQ+ movies, <a href="https://www.glbthotline.org/filmlist.html">click here.</a><br /> </i></div>

       </div>
      </div>

      <div class="card" id="sex-ed">
        <h1>Sex Ed</h1><hr/><br/><br/><br/><br/>
        <h3 id="teens">Teenagers</h3>
        <div><b>Queering Sex Ed</b> is a new project at Planned Parenthood Toronto that aims to create sex ed resources for LGBTQ youth specifically. They have created many great resources, covering everything from definitions to body positivity. Some of their information is summarized below, but make sure to check out everything else <a href="http://teenhealthsource.com/blog/queering-sexual-education/" target="_blank">here</a>!</div>
        <div>One of the most important issues in your life will be healthcare. Unfortunately, LGBTQ+ youth face a great deal of discrimination from doctors who may be homophobic or refuse to treat you properly. Even when a doctor is open and accepting, they often don't know or have experience with the unique issues that the LGBTQ+ community faces. Your comfort is the most important thing, so it’s crucial to find a doctor that you’re comfortable with! You can ask your LGBTQ+ friends or post on social media to find good doctors in your area, but remember that even a doctor with an amazing reputation may not be the right fit for you.</div>
        <div>During your appointment, advocate for yourself! Review with your doctor what you want to discuss and what will happen, take notes, ask questions whenever you don’t understand something, and talk about your feelings or concerns so that both of you are on the same page.</div>
        <div>The <a href="http://cdn0.genderedintelligence.co.uk/2012/11/17/17-14-04-GI-sexual-health-booklet.pdf" target="_blank">Trans Youth Sexual Health Booklet</a> is an amazing sex ed guide for trans youth!</div>
        <h3 id="parents-ed">Parents</h3>
        <div>It can be scary talking to your child about sex, especially if you have no idea what safe sex would be like for LGBTQ+ teens. However, talking to them about dating and sex is important to make your child feel accepted and loved, instead of lost and isolated. Be open to talking and listening with them, even if you don’t know everything about the topic. Use gender neutral language, and teach your child about healthy relationships too!</div>
        <h3 id="teachers">Teachers</h3>
        <div>As the LGBTQ movement progresses, new sex ed curriculums have been developed to be more inclusive of all sexual and gender identities. Advocates for Youth, an organization that advocates for young people's right to sexual health information, services, and resources, recently developed a new curriculum called <b>Rights, Respect, Responsibility</b>.</div>
        <div>Make sure to remember that younger students often stick to the gender binary, even when they are gender non-conforming, and won’t fully understand explanations of the gender spectrum. When teaching younger students, it’s recommended to use gender binary language like “boy” and “girl”, but older students should learn more inclusive language and have lessons where they can explore the concepts of gender and gender expression. However, younger students should still be exposed to and discuss same-sex relationships, or families with two moms/dads. This helps combat the assumption that relationships are only heterosexual, and includes students who may be questioning their sexual orientation.</div>
        <div>Read the teacher's guide to the curriculum <a href="https://advocatesforyouth.org/wp-content/uploads/2018/10/teachers-guide-1.pdf" target="_blank">here</a>.</div>
      </div>

      <div class="card" id="history">
        <h1>History</h1><hr/><br/><br/><br/><br/>
        <h5>Information sourced from GLSEN.</h5>
        <ul>
        <li> November 8th, 1838: Herculine Barbin's birthday - now celebrated as Intersex Day of Rememberance. Herculine Barbin was a French intersex person whose memoirs are some of the earliest records of intersex identity. </li>
        <li> 1886: We'wha, a Zuni Native American from New Mexico, is received by U.S. President Grover Cleveland as a "Zuni Princess". They are an accomplished weaver, potter, and the most famous Ihamana, a traditional Zuni gender role, now known as mixed-gender or Two-spirit.</li>
        <li> 1890: The birth of Alan Hart, a trans man who pioneered the use of X-ray photography in tuberculosis deterction and saved thousands of lives.</li>
        <li> 1892: The words "bisexual" and "homosexual " are used for the first time with the modern definitions of the words in Charles Gilbert Chaddock's translation of Richard von Krafft-Ebing's Psychopathia Sexualis.</li>
        <li> 1924: Henry Gerber forms the Society for Human Rights, the first gay activist group in the U.S. and publishes Friendship and Freedom, the first known American gay publication. However, the group and the publication are quickly shut down.</li>
        <li> 1925: Blues singer Ma Rainey is arrested in her home in Harlem for having a lesbian party. Her protégé, Bessie Smith, bails her out of jail the following morning. Rainey and Smith were part of an extensive circle of lesbian and bisexual African‐American women in Harlem.</li>
        <li> 1926: The New York Times is the first major publication to use the word "homosexuality." </li>
        <li> 1928: The Well of Loneliness, by Radclyffe Hall, is published in the United States. This sparks great legal controversy and brings the topic of homosexuality to public conversation.</li>
        <li> 1931: Lili Ilse Elvenes (also known as Lili Elbe), a Danish transgender woman, becomes one of the first recipients of gender affirmation surgery.</li>
        <li> 1950: The Mattachine Society is formed by activist Harry Hay as one of the first gay rights groups in the United States.</li>
        <li> 1952: Christine Jorgensen is the first American who comes forward publicly about being transgender, and speaks openly about her experiences with gender affirmation surgery and hormone replacement therapy. Her transition causes an international sensation, and for many, she is the first visible transgender person in the media.</li>
        <li> 1955: The Daughters of Bilitis (DOB), considered to be the first lesbian rights organization, is formed by Del Martin and Phyllis Lyon in San Francisco, California. The group is conceived as a social alternative to lesbian bars, which were considered illegal and thus subject to raids and police harassment.</li>
        <li> 1956: James Baldwin, African‐American novelist and intellectual, publishes his first novel, Go Tell It On the Mountain, a critically acclaimed work that explores bisexuality, as well as intimate relationships between men.</li>
        <li> 1961: José Sarria is the first openly gay person to run for public office in the United States, but did not win. He was a Colombian and Spanish activist and founded an international coalition of charities named the International Imperial Court System. It remains the second largest LGBT organization in the world today.</li>
        <li> 1963: Bayard Rustin, noted civil rights activist and gay man, is the chief organizer behind the historic March on Washington, which culminates with Dr. Martin Luther King, Jr’s famous “I Have a Dream” speech.</li>
        <li> 1964: The first gay rights demonstration in the United States takes place on September 19 at the Whitehall Induction Center in New York City, protesting against discrimination in the military.</li>
        <li> 1969: Police raid the Stonewall Inn in New York City in the early hours of June 28. This leads to four days of struggle between police and LGBT people. Transgender people, LGBT people of color, and youth are a major part of these “riots” that mark the birth of the modern LGBT movement.</li>
        <li> 1970:The first Gay Liberation Day March is held in New York City.</li>
        <li> 1977: Harvey Milk becomes the first openly gay person to be elected to public office in California when he wins a seat on the San Francisco Board of Supervisors.</li>
        <li> 1980: Democrats are the first political party to add “gay rights” to their platform during this Democratic Convention.</li>
        <li> 1985: Virginia Uribe begins Project 10, a program to support LGBT students in a Los Angeles high school.  The project is eventually adopted by the entire Los Angeles School District.</li>
        <li> 1987: Princess Diana opens UK's first HIV/AIDS unit at London's Middlesex Hospital. She shakes the hand of a patient, without gloves on, challenging public perceptions of AIDS being spread through skin contact.</li>
        <li> 1987: ACT UP (AIDS Coalition to Unleash Power), a direct-action activist group, is founded in the LGBT Community Center in NYC to bring attention to AIDS-related issues using civil disobedience.</li>
        <li> 1989: Denmark becomes the first country in the world to legally recognize same-sex unions, after passing a bill legalizing "registered partnerships" in a 71–47 vote.</li>
        <li> 1994: Death of Pedro Pablo Zamora, a Cuban-American AIDS educator and television personality. He was one of the first openly gay men with AIDS to be portrayed in popular media, bringing international attention to HIV/AIDS and LGBT issues through his appearance on MTV's reality television series, The Real World: San Francisco.</li>
        <li> 1996: Congress and President Bill Clinton approved the “National AIDS Memorial Grove Act” in 1996, which officially set aside the deLaveaga Dell land in Golden Gate Park to be the site of the  first AIDS memorial in the nation.</li>
        <li> 1997: Ellen DeGeneres and her television character, Ellen Morgan, come out.  Ellen becomes the first show to feature a lesbian or gay lead character. The show is cancelled the following year.</li>
        <li> 1998: Tammy Baldwin became the first openly lesbian candidate ever elected to Congress, winning Wisconsin's second congressional district seat over Josephine Musser.</li>
        <li> 1998: The first bisexual pride flag is unveiled.</li>
        <li> 2000: Vermont legalizes civil unions between those of the same sex.</li>
        <li> 2001: The Netherlands is the first country to legalize same-sex marriage.</li>
        <li> 2002: NYC expands the definition of “gender” to include protections for transgender and gender non-conforming people in employment, housing, and public accommodations in the NYC Human Rights Law.</li>
        <li> 2003: The U.S. Supreme Court overturns sodomy laws, proclaiming rights to privacy and decriminalizing “homosexual” behavior.</li>
        <li> 2004: Massachusetts becomes the first state to legally recognize same-sex marriage.</li>
        <li> 2006: Attorney and transgender activist Kim Coco Iwamoto is elected to the state‐level Board of Education in Hawaii. She is the first openly transgender person to be elected to a state level office in the United States.</li>
        <li> 2009: President Obama signs the Matthew Shepard and James Byrd, Jr. Hate Crimes and Prevention Act, also known as the Matthew Shepard Act, into law. The law expands the 1969 United States federal hate-crime law to include crimes motivated by a victim’s actual or perceived sexual orientation, gender identity, or disability, and becomes the first federal law to include legal protections for transgender people.</li>
        <li> 2011: The United States military policy “Don’t Ask, Don’t Tell” officially ends. This allows lesbian, gay, and bisexual people to serve openly in the military. The repeal of “Don’t Ask, Don’t Tell” does not lift regulations barring many transgender people from serving.</li>
        <li> 2012: The Food and Drug Administration approves Truvada• to be taken as a daily preventative for those at risk of acquiring HIV as PrEP (Pre-Exposure Prophylaxis). the Center for Disease Control notes that this is the first time a drug has been approved to prevent acquisition of sexually and intravenous transmission of HIV.</li>
        <li> 2012: Mark Takano becomes the first openly gay Asian-American elected to the United States House of Representatives.</li>
        <li> 2014: Laverne Cox is the first transgender woman to win an Emmy as an executive producer for Laverne Cox Presents: The T Word, a documentary. It aired on MTV.</li>
        <li> 2015: The Supreme Court rules that states are constitutionally required to issue marriage licenses to same-sex couples, legalizing marriage equality in all 50 states.</li>
        <li> 2015: The US Department of Health and Human Services issues an official revision that shortens the deferral period for blood donation from men who have sex with men. In addition to shortening the deferral period from a life ban to 12 months, the new revision includes the statement that for the purposes of questioning, gender should be considered on a "self-identified and self-reported" basis.</li>
        <li> 2016: President Obama dedicated the new Stonewall National Monument in Greenwich Village, Lower Manhattan, as the first United States National Monument to honor the LGBT Rights Movement.</li>
        <li> 2016: The United States policy banning transgender troops is repealed, making it possible for transgender people to enlist and serve openly in the army.</li>
        <li> 2017: The Trump Administration reinstates the United States policy banning transgender troops, making it so transgender people cannot enlist in the army. It is not clear what will happen to currently serving transgender troops.</li>

        </ul>
        <div><a href="https://gaycenter.org/archives/#archive-gallery">Click here</a> for an archive gallery from the LGBT Community Center, including photography, correspondence, news clippings, radio soundbytes, video broadcasts, and personal journals.</div>
      </div><br/><br/>
    </div>


    );

}

export default Education;
