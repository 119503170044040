import React, {Component} from 'react';
import Header from '../Header/Header';
import './Resources.css';
import {Link} from 'react-router-dom';
import DonateSite from './DonateSite';
import donateData from './donateData';

const Donate = () => {
  var site = donateData.map(s => (
    <DonateSite name={s.name} link={s.link} image={s.image} desc={s.desc}/>
  ));

  return (
    <div class='donateBody'>
      <link href="https://fonts.googleapis.com/css?family=Quicksand&display=swap" rel="stylesheet" />
      <Header pagename="resources"/>
      <h1 class="heading">Donate</h1>
      <p id="donate-top">Please consider supporting the incredible organizations and nonprofits that are fighting for LGBTQ+ rights and equality! Follow the links at the top to contribute.</p>
      <div id="donate-sites">
        {site}
      </div>
    </div>

  );

}

export default Donate;
