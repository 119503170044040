import React, {Component} from 'react';
import './Site.css';

const Site = (props) => {
  return (
    <div id="site-body">
      <p><a id="site-title" href={props.link} target="_blank">{props.name}</a></p>
      <div id="site-num">{props.num}</div>
      <p id="site-hours">{props.hours}</p>
      <p id="site-desc">{props.desc}</p>
    </div>
  )
}

export default Site;
