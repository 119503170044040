import React, {Component} from 'react';
import Firebase from 'firebase';
import firebase from '../firebase';
import {Stars,StarListener} from '../Stars/Stars';
import {updateAvg} from './ResultPage';
import './AddReview.css';

class AddReview extends Component{
  constructor(props){
    super(props);
    this.state={
      reviewComplete:false,
      rating:0,
      starListener:new StarListener(),
      reviewContent:'',
      user:null,
      signedIn:false,
      emailVerified:false,
      location:props.location,
      id:props.id,
      existingReview:false,
      ratingUpdated:false,
      reviewRef:null
    };
  }
  componentWillMount(){
    const db = firebase.firestore();
    firebase.auth().onAuthStateChanged(
      (user) => {
        if(user){
          var reviewRef = db.doc('locations/'+this.state.id+'/reviews/'+user.uid);
          this.setState({
            currentUser:user,
            signedIn:true,
            emailVerified:user.emailVerified,
            reviewRef:reviewRef
          });
          reviewRef.get().then(
            (doc) => {
              var exists = doc.exists;
              this.setState({existingReview:exists});
              if(exists){
                alert('Any given user may only submit one review per location. Instead of submitting a new review, you may edit your past review.');
                var review = doc.data();
                this.state.starListener.updateStarCount(review.rating);
                this.setState({
                  rating:review.rating,
                  reviewContent:review.reviewContent
                });
              }
            }
          )
        }
      }
    )
  }
  handleChange(e){
    this.setState({
      [e.target.name]:e.target.value
    })
  }
  handleStarUpdate(n){
    this.setState({
      rating:n
    })
  }
  handleSubmit(e){
    e.preventDefault();
    if(this.state.rating===0 || this.state.reviewContent===''){
      alert('please complete the rating and review.');
      return null;
    }
    console.log(this.state.currentUser);
    const {reviewRef} = this.state
    reviewRef.set({
      rating:this.state.rating,
      timestamp:Firebase.firestore.Timestamp.now(),
      reviewContent:this.state.reviewContent,
      user:this.state.currentUser.uid
    }).then(
      () => {
        let reviewCollRef = reviewRef.parent
        updateAvg(reviewCollRef,null,'addReview');
      }
    );
  }

  render(){
    if(!this.state.signedIn){
      return(
        <h3 className="verify-alert">Please sign in to submit a review</h3>
      )
    }
    if(!this.state.emailVerified){
      return(
        <h3 className="verify-alert">Verify your email to submit a review</h3>
      )
    }
    return(
      <div className="add-review-form">
        <form onSubmit={(e) => {this.handleSubmit(e)}}>
          Rating <Stars parent={this} starCount={this.state.rating} size="small" listener={this.state.starListener}/><br />
          <textarea maxlength="1000" className="review-area" name="reviewContent" value={this.state.reviewContent} onChange={(e) => this.handleChange(e)}/><br />
          <input id="submit-review" type="submit" value="Submit Review" name="submit" />
        </form>
      </div>
    );
  }
  // updateUserAndExit(db,replacing){
  //   if(!replacing){
  //     var userRef = db.doc('users/'+this.state.currentUser.uid);
  //     userRef.get().then(
  //       (doc) => {
  //         console.log(doc.data());
  //         var data = doc.data();
  //         data.reviews.push(this.state.reviewRef);
  //         userRef.set(data).then(
  //           () => {
  //             this.setState({reviewComplete:true});
  //             if(this.state.ratingUpdated){
  //               alert('Review successfully added! Thank you for your submission.');
  //               global.location.search='?id='+this.state.id;
  //             }
  //           }
  //         )
  //       }
  //     ).catch(
  //       (err) => {
  //         alert('failed');
  //         console.log(err);
  //       }
  //     );
  //   }else{
  //     this.setState({reviewComplete:true});
  //     if(this.state.ratingUpdated){
  //       alert('Review successfully replaced! Thank you for your submission.');
  //       global.location.search='?id='+this.state.id;
  //     }
  //   }
  // }
}
export default AddReview;
