import firebase from 'firebase';
// Your web app's Firebase configuration
  var firebaseConfig = {
    apiKey: "AIzaSyAp1-xY1ca4uLUU0-ADPBJhv66PdujfAfQ",
    authDomain: "queery-xx.firebaseapp.com",
    databaseURL: "https://queery-xx.firebaseio.com",
    projectId: "queery-xx",
    storageBucket: "queery-xx.appspot.com",
    messagingSenderId: "619109978202",
    appId: "1:619109978202:web:3fcef2de69918cd6"
  };
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);
  export default firebase;
