import React, {Component} from 'react';
import Header from '../Header/Header';
import './Faq.css';
import spectrum from './spectrum.png';
import orientation from './orientation.png';

class Faq extends Component {
  constructor(){
    super();
    this.state={
      displayOne:{
        display:"none"
      },
      displayTwo:{
        display:"none"
      },
      displayThree:{
        display:"none"
      },
      displayFour:{
        display:"none"
      },
      displayFive:{
        display:"none"
      },
    };
  }

  render(){
    return (
      <div id="faq-body">
        <link href="https://fonts.googleapis.com/css?family=Quicksand&display=swap" rel="stylesheet" />
        <div id="title-page">
        <Header pagename="resources"/>
        <h1 id="faq-title">FAQ</h1><br/>

        <table class="question_header">
          <tr>
            <td>
              <button onClick={this.submitOne}>What does LGBTQ+ mean?</button>
            </td>
          </tr>
          <tr style={this.state.displayOne}>
            <div class="answercard">
                <h2>Lesbian</h2>
                <h3>A woman whose enduring physical, romantic, and/or emotional attraction is to other women. Some lesbians may prefer to identify as gay or as gay women. </h3>
                <h2>Gay</h2>
                <h3>The adjective used to describe people whose enduring physical, romantic, and/or emotional attractions are to people of the same sex. Sometimes lesbian is the preferred term for women. </h3>
                <h2>Bisexual</h2>
                <h3>A person who has the capacity to form enduring physical, romantic, and/or emotional attractions to those of the same gender or to those of another gender. People may experience this attraction in differing ways and degrees over their lifetime. Bisexual people need not have had specific sexual experiences to be bisexual; in fact, they need not have had any sexual experience at all to identify as bisexual. </h3>
                <h2>Transgender</h2>
                <h3>An umbrella term for people whose gender identity and/or gender expression differs from what is typically associated with the sex they were assigned at birth. People under the transgender umbrella may describe themselves using one or more of a wide variety of terms— including transgender. Many transgender people are prescribed hormones by their doctors to bring their bodies into alignment with their gender identity. Some undergo surgery as well. But not all transgender people can or will take those steps, and a transgender identity is not dependent upon physical appearance or medical procedures. </h3>
                <h2>Queer / Questioning</h2>
                <h3>Queer is an adjective used by some people, particularly younger people, whose sexual orientation is not exclusively heterosexual. Typically, for those who identify as queer, the terms lesbian, gay, and bisexual are perceived to be too limiting and/or fraught with cultural connotations they feel don’t apply to them. Some people may use queer, or more commonly genderqueer, to describe their gender identity and/or gender expression. Once considered a pejorative term, queer has been reclaimed by some LGBT people to describe themselves; however, it is not a universally accepted term even within the LGBT community. Sometimes, when the Q is seen at the end of LGBT, it can also mean questioning. This term describes someone who is questioning their sexual orientation or gender identity.</h3>,
                <h2>+</h2>
                <h3>The plus is used to indicate that the acronym means everyone in the gender and sexually expansive community. For instance, if someone identifies as Gender Non-Conforming and Pansexual, that&apos;s not necessarily indicated by the existing letters, but this person may still identify as part of the expansive community.  The + allows everyone to be included! </h3>
            </div>
          </tr>


          <tr>
            <td>
              <button onClick={this.submitTwo}>What is the difference between gender and sex?</button>
            </td>
          </tr>
          <tr style={this.state.displayTwo}>
          <div class="answercard">
              <h2>Gender</h2>
              <h3>Your gender identity is how you feel inside and how you express those feelings.  Clothing, appearance, and behaviors can all be ways to express your gender identity. </h3>
              <h2>Sex</h2>
              <h3>Sex is a label — male or female — that you’re assigned by a doctor at birth based on the genitals you’re born with and the chromosomes you have. It goes on your birth certificate. </h3>
              <img class="answerimage" src={spectrum}/><br/><br/>
              <h3 class="center"><i>For an encompasing list of identities, <a href="/resources/education">click here.</a></i><br /></h3><br />
          </div>
          </tr>


          <tr>
            <td>
              <button onClick={this.submitThree}>What is the difference between sexuality and romantic identities?</button>
            </td>
          </tr>
          <tr style={this.state.displayThree}>
          <div class="answercard">
            <h2>Sexuality</h2>
            <h3> For many people, gender or sex have the biggest impact on how sexually attractive they find someone, but this doesn't mean that people who are attracted to a particular gender are attracted to all people of that gender or always unattracted to people of other genders. </h3>
            <h2>Romantic Identities</h2>
            <h3> the desire for intimate and emotional relationships with people of particular genders or sexes. It's about who we feel affection for and may include who we seek out to build a life or family with. </h3><br/>
            <br/><img class="answerimage2" src={orientation}/>
          <br/><h3 class="center"><i>For a more encompasing list of identities, <a href="/resources/education">click here.</a></i></h3><br />
          </div>
          </tr>


          <tr>
            <td>
              <button onClick={this.submitFour}>What struggles does the LGBTQ+ community face?</button>
            </td>
          </tr>
          <tr style={this.state.displayFour}>
            <div class="answercard">
            <h2>Systemic Discrimination</h2><br/>
            <ul>
            <li>Lack of Legal Protections</li><br/>
                  <div>In 28 states, there are no explicit statewide laws at all protecting people from discrimination on the basis of sexual orientation or gender identity in employment, housing and public accommodations (Freedom For All Americans). There are also many laws that restrict basic rights of the LGBTQ+ community. </div>
                  <ul>
                  <li> Anti-Transgender Bills </li>
                  <ul>
                    <li> These measures target transgender and nonbinary people for discrimination, such as by barring access to or even criminalizing the use of appropriate facilities, including restrooms, restricting transgender students’ ability to fully participate in school, authorizing healthcare discrimination against trans people, allowing religiously-motivated discrimination against trans people, or making it more difficult for trans people to get identification documents with their name and gender.  </li>
                  </ul>
                  <li> Religious Freedom Restoration Acts </li>
                  <ul>
                  <li> RFRAs make it easier for people to demand exemptions to generally applicable laws by allowing lawsuits challenging any governmental policy (such as non-discrimination laws) that someone says substantially burdens their religious beliefs. The government must prove that enforcing the policy is the least restrictive way of furthering a compelling governmental interest. </li>
                  </ul>
                  <li>  First Amendment Defense Acts (FADAs) </li>
                  <ul>
                  <li> Allow anyone - including government employees, contractors, and for-profit businesses – to act with impunity based on religious beliefs or moral convictions regarding marriage for same-sex couples, sexual activity outside of heterosexual marriage, and transgender people. This would open the door to unprecedented taxpayer-funded discrimination.  For example, government employees, government contractors or grantees – including those providing important social services like homeless shelters or drug treatment programs -- could discriminate against married same-sex couples and their families, single mothers, or anyone who has a sexual relationship outside of a marriage. </li>
                  </ul>
                  <li> Healthcare Exemptions </li>
                  <ul>
                  <li> Would allow those with religious objections to refuse care and undermine professional standards that apply equally to everyone. For instance, a high school guidance counselor could refuse to counsel a gay teenager, citing their sincerely held religious beliefs. </li>
                  </ul>
                  <li> Adoption & Foster Care Exemptions </li>
                  <ul>
                  <li> Would allow adoption and foster care agencies to refuse to provide any adoption or foster care services that would conflict with the religious beliefs of the agency, regardless of the best interests of the children in their care.   </li>
                  </ul>
                  <li> Marriage-Related Exemptions </li>
                  <ul>
                  <li> These bills create different types of religious exemptions regarding marriage.  Some of these bills apply to religious organizations, while others authorize discrimination by businesses or government officials.  Some bills explicitly target same-sex couples and others would allow discrimination based on religious beliefs about any marriage. </li>
                  </ul>

                  </ul>
            <li>Medical Discrimination</li><br/>
                        <div> Information gathered from <a href="https://www.americanprogress.org/issues/lgbt/news/2018/01/18/445130/discrimination-prevents-lgbtq-people-accessing-health-care/">the Center for American Progress </a><br /> </div>
                      <div> Discrimination in health care settings endangers LGBTQ people’s lives through delays or denials of medically necessary care. For example, after one patient with HIV disclosed to a hospital that he had sex with other men, the hospital staff refused to provide his HIV medication. In another case, a transgender teenager who was admitted to a hospital for suicidal ideation and self-inflicted injuries was repeatedly misgendered and then discharged early by hospital staff. He later committed suicide. Discrimination affects LGBTQ parents as well: In Michigan, an infant was turned away from a pediatrician’s office because she had same-sex parents. Even though many states, such as Michigan, lack explicit statewide laws against LGBTQ discrimination in health care, Section 1557 of the ACA provides federal protections. </div>
                      <ul>
                      <li>  Among LGBQ respondents who visited a doctor or health care providers&apos; office in the past year  </li>
                      <ul>
                      <li> 8 percent said that a doctor or other health care provider refused to see them because of their actual or perceived sexual orientation. </li>
                      <li> 9 percent said that a doctor or other health care provider used harsh or abusive language when treating them.</li>
                      <li> 7 percent said that they experienced unwanted physical contact from a doctor or other health care provider (such as fondling, sexual assault, or rape). </li>
                      </ul>
                      <li> Among transgender people who visited a doctor or health care providers&apos; office in the past year</li>
                      <ul>
                      <li>29 percent said a doctor or other health care provider refused to see them because of their actual or perceived gender identity. </li>
                      <li> 29 percent said that they experienced unwanted physical contact from a doctor or other health care provider (such as fondling, sexual assault, or rape). </li>
                      <li> 21 percent said a doctor or other health care provider used harsh or abusive language when treating them. </li>
                      </ul>
                      </ul>
             </ul>
            </div>
          </tr>


          <tr>
            <td>
              <button onClick={this.submitFive}>How can an ally help?</button>
            </td>
          </tr>
          <tr style={this.state.displayFive}>
          <div class="answercard">
              <h2>Friends</h2><br/>
              <h3>The best thing you can do for a friend if they come out to you is to make sure your friend knows that you're always there for them. Don't treat them any differently know that you know another part of their identity, because that might make them feel alienated. </h3>
              <h2>Parents</h2><br/>
              <h3>The best thing you can do for your child if they come out to you is to make sure they know you still love them. While you might be surprised or flustered, try to remember that it can be very scary for children to come out to their parents, especially with the threat of homelessness or estrangement. Start with  ensuring your child knows you love them and will  always be there for them.</h3>
              <br/><br/><h3><i>For more information, <a href="/resources/education">click here.</a></i></h3><br/><br/><br/><br/>
          </div>
          </tr>

        </table>
        </div><br/><br/><br/><br/><br/><br/>
      </div>
    )
  }

  submitOne = e => {
    if (this.state.displayOne.display === "none"){
      this.setState({
        displayOne:{
          display:"block",
        }
      });
    }
    else {
      this.setState({
        displayOne:{
          display:"none",
        }
      });
    }
  }
  submitTwo = e => {
    if (this.state.displayTwo.display === "none"){
      this.setState({
        displayTwo:{
          display:"block",
        }
      });
    }
    else {
      this.setState({
        displayTwo:{
          display:"none",
        }
      });
    }
  }
  submitThree = e => {
    if (this.state.displayThree.display === "none"){
      this.setState({
        displayThree:{
          display:"block",
        }
      });
    }
    else {
      this.setState({
        displayThree:{
          display:"none",
        }
      });
    }
  }
  submitFour = e => {
    if (this.state.displayFour.display === "none"){
      this.setState({
        displayFour:{
          display:"block",
        }
      });
    }
    else {
      this.setState({
        displayFour:{
          display:"none",
        }
      });
    }
  }
  submitFive = e => {
    if (this.state.displayFive.display === "none"){
      this.setState({
        displayFive:{
          display:"block",
        }
      });
    }
    else {
      this.setState({
        displayFive:{
          display:"none",
        }
      });
    }
  }
}

export default Faq;
