import React from 'react';
import Header from '../Header/Header';

import './Home.css';

const Home = () => {
    return(
      <div id='homeBody'>
        <link href="https://fonts.googleapis.com/css?family=Quicksand&display=swap" rel="stylesheet" />
        <link href="https://fonts.googleapis.com/css?family=Quicksand:500&display=swap" rel="stylesheet"/>
        <Header pagename="home"/>
        <div id="intro">
        <div class="glow">
          Queery
        </div>
        <div id="buttons">
          <a class="link" href="#about">Our Mission</a>
          <a class="link" href="#project">Our Project</a>
        </div>
        </div>

        <div id="about">
          <div id="about-text">
            <h1 class="heading">Our Mission</h1>
            <hr class="underline"/><br/> <br/>
            <p class="section-text">The LGBTQ+ community faces heavy discrimination everywhere they go, and resources, support, and safe places can be hard to find. Queery aims to create a community-sourced database of locations like shelters, hospitals, and accepting stores to make them more accessible to queer people in need, or to allies who want to find and support queer businesses. The website also collects reviews, so that users can hear from others in the community about their experience and contribute their own voice.</p>
            <div class="small-link"><a href="./map">Explore the Map</a></div>
          </div>
        </div>

        <div id="project">
          <div id="project-text">
            <h1 class="heading">Our Project</h1>
            <hr class="underline"/><br/> <br/>
            <p class="section-text">Queery was developed by Jackie Lin, Kiran Vuksanaj, Lina Yamahara, and Hilary Zen. We worked on this web application in Computer Science for Social Good at Cooper Union Summer STEM 2019 over six weeks to help solve the UNs Sustainable Development Goal #10, Reduced Inequality. We learned how to build a website, created the map and location database, added user accounts, and researched the LGBTQ community and the resources that currently exist.</p>
            <div class="small-link"><a href="./team">Meet the Team</a></div>
          </div>
        </div>

      </div>
    );
}

export default Home;
