import React, {Component} from 'react';
import Header from '../Header/Header';
import SmallSite from './SmallSite';
import activism from './activismData.js';
import protests from './protestData.js';
import {Link} from 'react-router-dom';
import './Activism.css';

const Activism = () => {
  var site = activism.map(s => (
    <SmallSite name={s.name} link={s.link} desc={s.desc} date={s.date}/>
  ));
  var protestSites = protests.map(s => (
    <SmallSite name={s.name} link={s.link} desc={s.desc} date={s.date}/>
  ));

  return (
    <div id="act-body">
      <Header pagename="activism"/>
      <div id="act-text">
        <h1 class="act-heading">Activism</h1>
        <p class="act-p">Thank you for your efforts to make the world a more welcoming and inclusive place for the LGBTQ+ community!</p>
        <table id="act-events">
          <tr>
            <th>Pride</th>
            <th>Protests</th>
          </tr>
          <tr>
            <td>
              {site}
            </td>
            <td>
              {protestSites}
            </td>
          </tr>
        </table>
      </div>
    </div>
  );

}

export default Activism;
