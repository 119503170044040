import React, {Component} from 'react';
import Header from '../Header/Header';
import Home from '../Home/Home'
import firebase from '../firebase';
import { Route, Redirect } from 'react-router'
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';

const uiConfig = {
  signInFlow: 'redirect',
  signInSuccessUrl:'/',

  callbacks: {
    signInSuccessWithAuthResult: () => false
  },
  signInOptions: [
    firebase.auth.EmailAuthProvider.PROVIDER_ID,
    // firebase.auth.FacebookAuthProvider.PROVIDER_ID,
    // firebase.auth.TwitterAuthProvider.PROVIDER_ID,
    // firebase.auth.GithubAuthProvider.PROVIDER_ID
  ]
}

class LoginPage extends Component{
  constructor(){
    super();
    this.state={
      signedIn: false,
      currentUser: null
    };
  }

  componentWillMount(){
    firebase.auth().onAuthStateChanged(user => {
      if(user){//when user is not 'falsy' i.e. null
        this.setState({
          signedIn:true,
          currentUser: user,
      });
    }
  });
  }

  render(){
    if (this.state.signedIn){
      return (
        <div>
          <Header />
          <h1 align="center"><br /><br /><br />You have signed in successfully.</h1>
          <a class="login" href="/">Home</a>
        </div>
      )
    }
    else{
    return(
      <div>
        <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
      </div>
    )
    }
  }
}

export default LoginPage;
