import React, {Component} from 'react';
import { Route, Redirect } from 'react-router'
import firebase from '../firebase';
import Header from '../Header/Header';
import ProfilePage from './ProfilePage';
import './ProfilePage.css';

class EmailReset extends Component{
  constructor(){
    super();
    this.state = {
      signedIn:false,
      currentUser:null,
      email:"",
      currentPassword:"",
      doneUser:false,
      doneData:false
    }
  }

  componentDidMount(){
    firebase.auth().onAuthStateChanged(user => {
      if(user){//when user is not 'falsy' i.e. null
        this.setState({
          signedIn:true,
          currentUser: user,
      });
    }
  });
  }

  render(){
    if (this.state.signedIn && this.state.currentUser.emailVerified){
      var user = this.state.currentUser;
      return(
        <div>
          <Header />
          <h1>Your Account Settings</h1>
          <hr /><br />
          <div class="page">
            <div class="sidenav">
              <a href="/profile/info">Your Profile</a>
              <a class="selected" href="/profile/email">Email Reset</a>
              <a href="/profile/password">Password Reset</a>
            </div>
            <div class="main">
              <form onSubmit={this.handleSubmit}><br />
                Current Password:<br />
                <input type="password" name="currentPassword" onChange={this.updateInput} value={this.state.currentPassword}></input>
                <br /><br />
                New Email:<br/>
                <input maxlength="254" type="email" name="email" onChange={this.updateInput} value={this.state.email}></input>
                <br /><br />
                Confirm New Email:
                <input maxlength="254" name="confirm" type="email" value={this.state.confirm} onChange={this.updateInput}>
                </input><br /><br />
                <button type="submit">Submit</button>
              </form>
            </div>
            <div class="submit">
            </div>
          </div>
        </div>
      )
    }
    else if (this.state.signedIn){
      return (
        <div>
          <Header />
          <h1>Your Account Settings</h1>
          <hr /><br />
          <div class="page">
            <div class="sidenav">
              <a href="/profile/info">Your Profile</a>
              <a class="selected" href="/profile/email">Email Reset</a>
              <a href="/profile/password">Password Reset</a>
            </div>
            <div class="main">
            <br /><br /><br /><br /><br /><br />
              <h3 align="center">Please verify your email to access this feature.</h3>
            </div>
            <div class="edit">
            </div>
          </div>
        </div>
      )
    }
    else {
    return(
      <div>
        <Header />
        <h1>Your Account Settings</h1>
        <hr /><br />
        <div class="page">
          <div class="sidenav">
            <a href="/profile/info">Your Profile</a>
            <a class="selected" href="/profile/email">Email Reset</a>
            <a href="/profile/password">Password Reset</a>
          </div>
          <div class="main">
          </div>
          <div class="edit">
          </div>
        </div>
      </div>
      )
    }
}

  updateInput = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  handleSubmit = e => {
    e.preventDefault();
    let user = firebase.auth().currentUser;
    if (this.state.confirm === this.state.email){
      this.reauthenticate(this.state.currentPassword).then(() => {
        if (this.state.email !== user.email){
          user.updateEmail(this.state.email).then(() => {
            user.sendEmailVerification();
            this.setState({doneUser:true});
            if (this.state.doneData && this.state.doneUser){
              alert('Email was successfully updated!');
            }
          }).catch((error) => {
            alert(error.code);
          });
        }
      }).catch((error) => {
        alert(error.code);
      });

      const db = firebase.firestore(); //database
      let usersdb = db.collection('users'); //access the reviews database
      let query = usersdb.doc(user.uid);
      if (this.state.email !== null){
        query.update({email:this.state.email,}).then( () => {
          this.setState({doneData: true});
        }).then( () => {
          if (this.state.doneData && this.state.doneUser){
            alert('Email was successfully updated!');
          }
        });
      }
    }
    else {
      alert("Emails don't match!");
    }
  }

  reauthenticate = (currentPassword) => {
    var user = firebase.auth().currentUser;
    var cred = firebase.auth.EmailAuthProvider.credential(user.email, currentPassword);
    return user.reauthenticateWithCredential(cred);
  }
}

export default EmailReset;
