import React, {Component} from 'react';
import { Route, Redirect } from 'react-router'
import firebase from '../firebase';
import Header from '../Header/Header';
import ProfilePage from './ProfilePage';
import './ProfilePage.css';

class ProfilePageEdit extends Component{
  constructor(){
    super();
    this.state = {
      signedIn:false,
      currentUser:null,
      name:null,
      gender:[],
      orientation:[],
      age:null,
      doneName:false,
      doneAge:false,
      doneGender:false,
      doneOrientation:false,
    }
  }

  componentDidMount(){
    firebase.auth().onAuthStateChanged(user => {
      if(user){//when user is not 'falsy' i.e. null
        this.setState({
          signedIn:true,
          currentUser: user,
      });
    }
  });
  }

  render(){
    if (this.state.signedIn){
      var user = this.state.currentUser;
      return(
        <div>
          <Header />
          <h1>Your Account Settings</h1>
          <hr /><br />
          <div class="page">
            <div class="sidenav">
              <a class="selected" href="/profile/info">Your Profile</a>
              <a href="/profile/email">Email Reset</a>
              <a href="/profile/password">Password Reset</a>
            </div>
            <div class="main">
              <form onSubmit={this.handleSubmit}><br />
                <table>
                  <tr>
                    <td>Name</td>
                    <td>Age</td>
                  </tr>
                  <tr>
                    <td>
                    <input
                      maxlength="48"
                      type="text"
                      name="name"
                      onChange={this.updateInput}
                      value={this.state.name}>
                    </input>
                    </td>
                    <td>
                    <input
                      type="number"
                      name="age"
                      min="0"
                      max="120"
                      onChange={this.updateInput}
                      value={this.state.age}>
                    </input>
                    </td>
                  </tr>
                  <tr>
                    <td><br /><br />Gender</td>
                    <td><br /><br />Sexual Orientation</td>
                  </tr>
                  <tr>
                    <td>
                    <input type="checkbox" name="gender" value="Male" onClick={this.updateCheckBox}/>
                    <span class="choice">Male</span><br />

                    <input type="checkbox" name="gender" value="Female" onClick={this.updateCheckBox} />
                    <span class="choice">Female</span><br />

                    <input type="checkbox" name="gender" value="Transgender" onClick={this.updateCheckBox} />
                    <span class="choice">Transgender</span><br />

                    <input type="checkbox" name="gender" value="Nonbinary" onClick={this.updateCheckBox} />
                    <span class="choice">Nonbinary</span><br />

                    <input type="checkbox" name="gender" value="Nonconforming" onClick={this.updateCheckBox} />
                    <span class="choice">Nonconforming</span><br />

                    <input type="checkbox" name="gender" value="Other" onClick={this.updateCheckBox} />
                    <span class="choice">Other</span><br />
                    </td>

                    <td valign="top">
                    <input type="checkbox" name="orientation" value="Straight" onClick={this.updateCheckBox} />
                    <span class="choice">Straight</span><br />

                    <input type="checkbox" name="orientation" value="Gay" onClick={this.updateCheckBox} />
                    <span class="choice">Gay</span><br />

                    <input type="checkbox" name="orientation" value="Lesbian" onClick={this.updateCheckBox} />
                    <span class="choice">Lesbian</span><br />

                    <input type="checkbox" name="orientation" value="Bisexual" onClick={this.updateCheckBox} />
                    <span class="choice">Bisexual</span><br />

                    <input type="checkbox" name="orientation" value="Asexual" onClick={this.updateCheckBox} />
                    <span class="choice">Asexual</span><br />

                    <input type="checkbox" name="orientation" value="Pansexual" onClick={this.updateCheckBox} />
                    <span class="choice">Pansexual</span><br />

                    <input type="checkbox" name="orientation" value="Polysexual" onClick={this.updateCheckBox} />
                    <span class="choice">Polysexual</span><br />

                    <input type="checkbox" name="orientation" value="Other" onClick={this.updateCheckBox} />
                    <span class="choice">Other</span><br />
                    </td>
                  </tr>
                </table><br /><br />
                <button type="submit">Submit</button>
              </form>
            </div>
            <div class="submit">
            </div>
          </div>
        </div>
      )
    }
    else {
    return(
      <div>
        <Header />
        <h1>Your Account Settings</h1>
        <hr /><br />
        <div class="page">
          <div class="sidenav">
            <a class="selected" href="/profile/info">Your Profile</a>
            <a href="/profile/email">Email Reset</a>
            <a href="/profile/password">Password Reset</a>
          </div>
          <div class="main">
          </div>
          <div class="edit">
          </div>
        </div>
      </div>
      )
    }
}

  updateInput = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  updateCheckBox = e => {
    if (e.target.checked){
      this.state[e.target.name].push(e.target.value);
    }
    else {
      var index = this.state[e.target.name].indexOf(e.target.value);
      if (index > -1) this.state[e.target.name].splice(index, 1);
    }
  }

  handleSubmit = e => {
    let user = this.state.currentUser;

    this.state.gender.sort();
    for (var i = 0; i < this.state.gender.length - 1; i++){
      this.state.gender[i] = this.state.gender[i] + ", ";
    }

    this.state.orientation.sort();
    for (var i = 0; i < this.state.orientation.length - 1; i++){
      this.state.orientation[i] = this.state.orientation[i] + ", ";
    }

    e.preventDefault();

    const db = firebase.firestore();
    db.settings({
      timestampsInSnapshots: true
    });

    //update profile
    if (this.state.name !== null) {
      user.updateProfile({
        displayName:this.state.name,
      });
    }

    //update database
    let usersdb = db.collection('users'); //access the reviews database
    let query = usersdb.doc(user.uid);
    if (this.state.name !== null){
      query.update({displayName:this.state.name,}).then( () => {
        this.setState({doneName: true});
      }).then( () => {
        if (this.state.doneName && this.state.doneAge && this.state.doneGender && this.state.doneOrientation){
          global.location.pathname = "/profile/info"

        }
      });
    }
    else {
      this.setState({doneName: true});
    }

    if (this.state.age !== null){
      query.update({age:this.state.age,}).then( () => {
        this.setState({doneAge: true});
      }).then( () => {
        if (this.state.doneName && this.state.doneAge && this.state.doneGender && this.state.doneOrientation){
          global.location.pathname = "/profile/info";
        }
      });
    }
    else {
      this.setState({doneAge: true});
    }

    if (this.state.gender.length > 0){
      query.update({gender:this.state.gender,}).then( () => {
        this.setState({doneGender: true});
      }).then( () => {
        if (this.state.doneName && this.state.doneAge && this.state.doneGender && this.state.doneOrientation){
          global.location.pathname = "/profile/info";
        }
      });
    }
    else {
      this.setState({doneGender: true});
    }

    if (this.state.orientation.length > 0){
      query.update({orientation:this.state.orientation,}).then( () => {
        this.setState({doneOrientation: true});
      }).then( () => {
        if (this.state.doneName && this.state.doneAge && this.state.doneGender && this.state.doneOrientation){
          global.location.pathname = "/profile/info";
        }
      });
    }
    else {
      this.setState({doneOrientation: true});
    }

    this.setState({
      name:null,
      gender:[],
      orientation:[],
      age:null,
    });

  }
}

export default ProfilePageEdit;
