import React, {Component} from 'react';
import './stars.css';
import star_empty from './star_empty.svg';
import star_full from './star_full.svg';

class StarListener{
  addStar(star){
    this.star = star;
  }
  updateStarCount(n){
    if(this.star){
      this.star.setState({starCount:n});
    }
  }
}

class Stars extends Component{
  constructor(props){
    super(props);
    if(props.listener) props.listener.addStar(this);
    this.state = {
      starCount:props.starCount,
      parent:props.parent,
      small:(props.size==="small")
    }
  }
  handleUpdate(e){
    this.setState({
      starCount:e.currentTarget.id
    })
    this.state.parent.handleStarUpdate(e.currentTarget.id);
  }
  returnStars(n){
    var out = [];
    for(var i=1;i<=5;i++){
      if(i<=n) out.push(
        <img className={this.state.small ? "star-small" : "star"} id={i} src={star_full} onClick={(e) => {this.handleUpdate(e)}} alt="" />
      );
      else out.push(
        <img className={this.state.small ? "star-small" : "star"} id={i} src={star_empty} onClick={(e) => {this.handleUpdate(e)}} alt="" />
      );
    }
    return out;
  }
  render(){
    return(
      <div className="star-holder">
        {this.returnStars(this.state.starCount)}
      </div>
    )
  }
}
export {StarListener,Stars};
