import React from 'react';
const faq = () => {
  return(
    <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">

     <g>
      <title>background</title>
      <rect fill="none" id="canvas_background" height="102" width="102" y="-1" x="-1"/>
     </g>
     <g>
      <title>Layer 1</title>
      <path id="svg_1" d="m50,70.159c-2.061,0 -3.736,1.677 -3.736,3.735c0,2.06 1.676,3.735 3.736,3.735c2.061,0 3.736,-1.676 3.736,-3.735c0,-2.058 -1.675,-3.735 -3.736,-3.735z"/>
      <path id="svg_2" d="m50,5c-24.813,0 -45,20.187 -45,45c0,24.813 20.187,45 45,45c24.813,0 45,-20.188 45,-45c0,-24.813 -20.187,-45 -45,-45zm0,85.08c-22.1,0 -40.08,-17.98 -40.08,-40.08c0,-22.1 17.98,-40.08 40.08,-40.08c22.1,0 40.08,17.98 40.08,40.08c0,22.1 -17.98,40.08 -40.08,40.08z"/>
      <path id="svg_3" d="m50,23.907c-7.978,0 -14.602,5.979 -15.405,13.911c-0.063,0.629 0.135,1.234 0.562,1.706c0.44,0.487 1.076,0.767 1.744,0.767l0.149,0c1.243,0 2.306,-0.975 2.474,-2.266c0.679,-5.245 5.183,-9.2 10.477,-9.2c5.824,0 10.563,4.74 10.563,10.565c0,2.822 -1.1,5.476 -3.096,7.473c-0.316,0.317 -0.811,0.717 -1.412,1.207c-2.955,2.391 -7.898,6.393 -8.465,14.564c-0.043,0.632 0.172,1.235 0.605,1.7c0.443,0.475 1.072,0.745 1.729,0.745l0.148,0c1.262,0 2.335,-1.015 2.443,-2.31c0.494,-5.904 4.041,-8.777 6.697,-10.932c0.667,-0.54 1.242,-1.007 1.733,-1.499c2.925,-2.928 4.534,-6.815 4.534,-10.95c0.004,-8.534 -6.941,-15.481 -15.48,-15.481z"/>
     </g>
    </svg>
  )
}
export default faq;
