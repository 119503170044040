import React from 'react';
import ResourceAdd from './ResourceAdd';
import addButton from './add-button.js';
import './Map.css';

const Result = (props) => {
  var types = "";
  for (var i = 0; i < props.point.type.length; i++) {
    types = types + props.point.type[i] + ", "
  }
  types = types.substring(0, types.length - 2);
  return (
    <div class={props.isActive ? 'result-active' : 'result'} >
      {props.isActive ?
        (
          //ACTIVE card
          <div>
            <div className="fullContent">
              <h3 class="cardtitle">{props.point.name}</h3>
              <div class="extraContent">
                <p><i>{types}</i></p>
                <p>{props.point.description}</p>
                <p>{props.point.address}<br /><br /></p>
                <p>Rating: {(props.point.rating===0)? "no ratings yet" : props.point.rating + " stars"}</p>
              </div>
            </div>
            <div className="active-card-links">
              <div className="link-to-page">
                <a className="cardButton" href={'/result?id='+props.point.id}>&gt;</a>
              </div>
              <div>
                <a href={'/result?addReview=true&id='+props.point.id} className="cardButton">+</a>
              </div>
            </div>
          </div>
        ) : (
          //MINIMIZED card
          <div>
            <h3 class="cardtitle">{props.point.name}</h3><br />
            <i>{types}</i>
            <div class="extraContent-minimized">
              <p>{(props.point.rating===0)? "no ratings yet" : props.point.rating + " stars"}</p>
            </div>
          </div>
        )
      }
    </div>
  );
}

const Results = (props) => {
  return (
    <div id="results-wrapper">
      <div className="results-header">
        <div className="results-title">
          Results
        </div>
        <div className="add-resource" onClick={() => {props.parent.openResourceAdd()}} box-open={props.parent.state.addResourceOpen ? 't' : 'f'}>
          {addButton()}
        </div>
      </div>
      {props.parent.state.addResourceOpen ? <ResourceAdd page={props.parent} /> : ''}
      {/*(props.list.length === 0) ? "" : <Result point={props.list[props.active]} isActive="yes" />*/}
      <div className="results-sub-header">
        <p>Showing {props.list.length} results</p>
      </div>
      {props.list.map((p,i) => {
        return(
          <div id={"result"+i} onClick={() => {props.parent.resultSelected(i)}}>
            <Result parent={props.parent} point={p} isActive={props.active===i} />
            </div>
          );
      })}
    </div>
  );
}

export default Results;
