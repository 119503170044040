import React from 'react';
import favicon from './logo.png';
import Login from './Login';
import './Header.css';

const Header = (props) => {
  var team = "menu-button";
  if (props.pagename === "team") team = "menu-button active";

  var resources = "menu-button";
  if (props.pagename === "resources") resources = "menu-button active";

  var map = "menu-button";
  if (props.pagename === "map") map = "menu-button active";

  var home = "menu-button";
  if (props.pagename === "home") home = "menu-button active";

  return (
    <div>
      <div className="top-header">
        <Login />
      </div>
      <div className="bottom-header">
        <div id="favicon-holder">
          <a href="/">
            <img id="favicon" src={favicon} alt="Queery - Home"/>
          </a>
        </div>
        <a className={team} href="/team">Meet the Team</a>
        <a className={resources} href="/resources">Resources</a>
        <a className={map} href="/map">Map</a>
        <a className={home} href="/">Home</a>
      </div>
    </div>
  )
}

export default Header;
