import React from 'react';
const heart = () => {
  return(
    <svg viewBox="0 0 150 150" xmlns="http://www.w3.org/2000/svg">
     <title>Artboard 1</title>

     <g>
      <title>background</title>
      <rect fill="none" id="canvas_background" height="150" width="150" y="-1" x="-1"/>
     </g>
     <g>
      <title>Layer 1</title>
      <path id="svg_1" d="m134.18,24.54a38.81,38.81 0 0 0 -54.88,0l-4.3,4.28l-4.31,-4.31a38.77,38.77 0 1 0 -54.78,54.83l56.55,56.55a3.58,3.58 0 0 0 2.52,1l0,0a3.5,3.5 0 0 0 2.47,-1l56.67,-56.46a38.88,38.88 0 0 0 0.06,-54.91l0,0.02zm-4.7,50.23l-0.35,-0.35l-54.13,53.98l-54,-54a31.68,31.68 0 1 1 44.71,-44.86l6.81,6.8a3.56,3.56 0 0 0 5,0l6.75,-6.74a31.71,31.71 0 0 1 44.84,44.84l0.35,0.35l0.02,-0.02z"/>
     </g>
    </svg>
  );
}
export default heart;
