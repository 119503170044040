import React, {Component} from 'react';
import {Redirect} from 'react-router';
import firebase from '../firebase';
import isAdmin from './admins'
import './admin.css';
import {updateAvg} from '../ResultPage/ResultPage';
import Header from '../Header/Header';

class ReportedUser extends Component {
  db = firebase.firestore();
  constructor(props){
    super(props);
    this.state = {
      show:true,
      id:props.id,
      reports:props.reports,
      displayNames:props.displayNames
    }
  }
  delete(){
    const {id} = this.state;
    this.db.doc('users/'+id).update({banned:true,reviews:[]}).then(
      () => {
        this.db.collectionGroup('reviews').where('user','==',id).get().then(
          (snapshot) => {
            snapshot.forEach(
              (doc) => {
                updateAvg(doc.ref.parent)
                doc.ref.delete()
              }
            )
          }
        )
        this.setState({show:false});
      }
    )
  }
  clear(){
    const {id} = this.state;
    this.db.collection('users/'+id+'/reports').get().then(
      (snapshot) => {
        snapshot.forEach((doc) => doc.ref.delete());
        this.setState({show:false});
      }
    )
  }
  render(){
    const {show,reports,displayNames,id} = this.state;
    console.log(displayNames);
    if(!show) return '';
    return(
      <div className="reported-user">
        <p><b>Reported User: </b><a href={"/contributions?id="+id}>{id in displayNames ? displayNames[id] : id}</a></p>
        {reports.map((report =>
          {
            const {by,comments,reason,timestamp} = report;
            return(
              <div className="indvReport">
                <br/><p><b>By: </b>{by}</p>
                <p><b>Comments: </b>{comments}</p>
                <p><b>Reason: </b>{reason}</p>
                <p><b>Time: </b>{timestamp.toDate().toString()}</p>
              </div>
            );
          }))}
        <a href="#delete" className="button" id="deny" onClick={() => this.delete()}>Delete User</a>
        <a href="#clear" className="button" id="success" onClick={() => this.clear()}>Clear Reports</a>
      </div>
    )
  }
}

class ResultAdminView extends Component {
  constructor(props){
    super(props);
    this.state = {
      show:true,
      result:props.result.data(),
      doc:props.result.ref
    }
  }
  approve(){
    const {doc,result} = this.state;
    doc.update({verified:true}).then(
      () => {
        this.setState({
          show:false,
          review:Object.assign(result,{verified:true})
        });
      }
    )
  }
  deny(){
    const {doc} = this.state;
    doc.delete().then(
      () => {
        this.setState({
          show:false
        })
      }
    )
  }
  render(){
    const {result,show} = this.state;
    if(!show){
      return(
        <div className="admin-result-card">
          <p>{result.verified ? '(Resource approved)' : '(Resource deleted)'}</p>
        </div>
      )
    }
    return(
      <div className="admin-result-card">
        <p><b>Name:</b> {result.name}</p>
        <p><b>Address:</b> {result.address} </p>
        <p><b>Description:</b> {result.description}</p>
        <p><b>Added by:</b> {result.addedBy}</p>
        <a href="#submit" className="button" id="success" onClick={() => this.approve()}>Approve</a>
        <a href="#submit" className="button" id="deny" onClick={() => this.deny()}>Deny</a>
      </div>
    )
  }
}

class Administrator extends Component{
  db = firebase.firestore();
  auth = firebase.auth();
  constructor(){
    super();
    this.state = {
      display:false,
      user:null,
      userChecked: false,
      reportedUsers: {},
      unapprovedResources: [],
      displayNames: {}
    };
  }
  componentWillMount(){
    this.auth.onAuthStateChanged(
      (user) => {
        if(user){
          if(isAdmin(user)){
            this.setState({
              display:true,
              user:user,
              userChecked:true
            });
            this.getAdminData();
          }else{
            this.setState({
              display:false,
              user:user,
              userChecked:true
            })
          }
        }else{
          this.setState({
            display:false,
            user:null,
            userChecked:true
          })
        }
      }
    )
  }
  getAdminData(){
    // console.log(this.auth.currentUser);
    if(isAdmin(this.auth.currentUser)){
      console.log('getting');
      let unapprovedResources = this.db.collection('locations').where('verified','==',false);
      unapprovedResources.get().then(
        (snapshot) => {
          console.log(snapshot);
          this.setState({
            unapprovedResources:snapshot.docs
          })
        }
      );
      this.db.collectionGroup('reports').get().then(
        (snapshot) => {
          let reportedUsers = {};
          snapshot.forEach(
            (doc) => {
              let userID = doc.ref.parent.parent.id;
              if(userID in reportedUsers){
                reportedUsers[userID].push(doc.data())
              }else{
                reportedUsers[userID] = [doc.data()]
              }
            }
          );
          this.getNames(reportedUsers);
        }
      )
    }else{
      console.log('alert: false admin call');
    }
  }
  getNames(reportedUsers){
    let ids = Object.keys(reportedUsers);
    let displayNames = {};
    ids.forEach((id) => {
      this.db.doc('users/'+id).get().then(
        (doc) => {
          displayNames[id] = doc.data().displayName;
          if(ids.length===Object.keys(displayNames).length){
            this.setState(
              {
                displayNames:displayNames,
                reportedUsers:reportedUsers
              });
          }
        }
      )
    })
  }
  render(){
    const {
      display,
      // user,
      userChecked,
      unapprovedResources,
      reportedUsers,
      displayNames
    } = this.state;
    console.log(displayNames);
    if(userChecked && !display) return <Redirect to="/" />
    return(
      <div className="administrator-page">
        <Header />
        <h1>Admin Panel</h1>
        <div className="exteriorAdmin">
        <div className="resultsAdmin">{unapprovedResources.map((res) => <ResultAdminView result={res} />)}</div>
        <div className="reportsAdmin">{Object.keys(reportedUsers).map((id) =>
          <ReportedUser id={id} reports={reportedUsers[id]} displayNames={displayNames}/>
        ) }
        </div>
        </div>
      </div>
    );
  }
}
export default Administrator;
