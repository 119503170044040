import React from 'react';
const donate = () => {
  return(
    <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
     <g>
      <title>background</title>
      <rect fill="none" id="canvas_background" height="102" width="102" y="-1" x="-1"/>
     </g>
     <g>
      <title>Layer 1</title>
      <g id="svg_1" display="none">
       <rect id="svg_2" height="751.558" width="1220.125" stroke-miterlimit="10" stroke-width="4" stroke="#000000" fill="#000000" y="-377.496" x="-723.065"/>
      </g>
      <g id="svg_3">
       <path id="svg_4" d="m34.806,46.843l-1.123,1.119l-1.124,-1.12c-2.265,-2.26 -5.953,-2.26 -8.218,0c-1.097,1.093 -1.701,2.549 -1.702,4.1c0,1.551 0.604,3.008 1.702,4.102l9.242,9.216l0.095,-0.094l0.098,0.097l9.246,-9.219c1.098,-1.094 1.702,-2.551 1.701,-4.102c0,-1.551 -0.604,-3.007 -1.701,-4.101c-2.262,-2.256 -5.95,-2.258 -8.216,0.002z" fill="none"/>
       <path id="svg_5" d="m34.232,43.615l-0.548,0.348l-0.548,-0.348c-1.424,-0.904 -3.047,-1.345 -4.663,-1.345c-2.263,0 -4.512,0.864 -6.185,2.531c-1.643,1.637 -2.548,3.819 -2.548,6.142s0.905,4.505 2.548,6.143l11.304,11.271l0.097,-0.096l0.092,0.092l11.3,-11.268c1.643,-1.638 2.548,-3.82 2.548,-6.143s-0.905,-4.505 -2.548,-6.143c-2.868,-2.855 -7.431,-3.354 -10.849,-1.184zm8.792,11.431l-9.246,9.219l-0.098,-0.097l-0.095,0.094l-9.242,-9.216c-1.098,-1.094 -1.702,-2.551 -1.702,-4.102c0.001,-1.551 0.605,-3.007 1.702,-4.1c2.265,-2.26 5.953,-2.261 8.218,0l1.124,1.12l1.123,-1.119c2.266,-2.26 5.954,-2.259 8.218,0c1.097,1.094 1.701,2.55 1.701,4.101c-0.001,1.549 -0.606,3.006 -1.703,4.1z"/>
       <path id="svg_6" d="m83.713,64.276c-2.324,-2.391 -5.572,-3.762 -8.911,-3.762l-25.104,0c-3.571,0 -6.475,2.895 -6.475,6.453s2.904,6.454 6.475,6.454l10.686,0l-2.422,1.838c-3.319,2.517 -9.251,3.469 -12.709,2.777l-15.768,-3.151c-2.979,-0.595 -6.092,0.274 -8.329,2.326c-1.01,0.927 -1.482,2.288 -1.264,3.639c0.218,1.352 1.096,2.497 2.347,3.062l21.118,9.534c2.331,1.053 4.964,1.318 7.409,0.748l21.14,-4.248c0.997,-0.233 2.005,-0.348 3.004,-0.348c2.942,0 5.824,0.993 8.197,2.883l2.772,2.208c0.61,0.485 1.56,0.377 2.043,-0.227c0.241,-0.302 0.351,-0.678 0.308,-1.061c-0.043,-0.384 -0.233,-0.727 -0.535,-0.968l-2.773,-2.208c-3.878,-3.089 -8.847,-4.22 -13.634,-3.103l-21.14,4.248c-1.847,0.432 -3.84,0.236 -5.591,-0.556l-21.117,-9.534c-0.363,-0.155 -0.617,-0.484 -0.681,-0.895c-0.066,-0.39 0.069,-0.779 0.372,-1.054c1.55,-1.424 3.714,-2.027 5.784,-1.613l15.767,3.151c5.157,1.029 13.416,-0.862 16.754,-4.943l0.97,-1.186c0.636,-0.78 0.764,-1.828 0.332,-2.735c-0.434,-0.909 -1.33,-1.474 -2.34,-1.474l-10.7,0c-1.97,0 -3.572,-1.599 -3.572,-3.564s1.602,-3.564 3.572,-3.564l25.104,0c2.557,0 5.044,1.05 6.822,2.881l10.878,11.193c0.14,0.143 0.313,0.256 0.512,0.335c0.53,0.208 1.138,0.09 1.543,-0.302c0.279,-0.269 0.435,-0.63 0.441,-1.015c0.006,-0.385 -0.14,-0.749 -0.409,-1.026l-10.876,-11.193z"/>
       <path id="svg_7" d="m22.292,34.612l21.23,0c3.142,0 5.698,-2.548 5.698,-5.679s-2.556,-5.679 -5.698,-5.679l-9.304,0l3.699,-1.93c2.679,-1.396 6.684,-2.056 9.318,-1.529l13.33,2.664c2.404,0.48 4.917,-0.14 6.821,-1.665c0.874,-0.7 1.522,-1.673 1.595,-2.79c0.102,-1.571 -0.772,-2.977 -2.153,-3.599l-17.853,-8.06c-2.019,-0.912 -4.291,-1.142 -6.395,-0.648l-17.886,3.594c-3.253,0.757 -6.644,-0.006 -9.273,-2.101l-2.275,-1.813c-0.582,-0.464 -1.446,-0.489 -1.98,0.029c-0.331,0.32 -0.489,0.759 -0.439,1.205c0.043,0.383 0.233,0.727 0.535,0.968l2.343,1.867c3.336,2.657 7.604,3.63 11.707,2.669l17.888,-3.595c1.505,-0.352 3.142,-0.191 4.576,0.456l16.922,6.91c1.085,0.443 1.465,1.788 0.772,2.733c-1.233,0.942 -2.81,1.309 -4.332,1.007l-13.33,-2.664c-4.42,-0.882 -10.813,1.205 -14.382,4.257l-0.743,0.909c-0.562,0.688 -0.781,1.631 -0.463,2.46c0.365,0.953 1.252,1.556 2.259,1.556l8.937,0c1.35,0 2.589,0.909 2.847,2.234c0.346,1.781 -1.018,3.345 -2.74,3.345l-21.222,0c-2.114,0 -4.138,-0.855 -5.611,-2.371l-9.194,-9.459c-0.14,-0.143 -0.312,-0.256 -0.511,-0.334c-0.174,-0.068 -0.357,-0.102 -0.537,-0.102c-0.371,0 -0.734,0.14 -1.008,0.404c-0.278,0.268 -0.434,0.628 -0.44,1.013c-0.006,0.385 0.139,0.751 0.407,1.028l9.198,9.462c2.019,2.077 4.791,3.248 7.687,3.248z"/>
      </g>
     </g>
    </svg>
  );
}
export default donate;
