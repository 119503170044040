const admins = [
  'aaKplTpdPgZp3xZ32IX5vTHs67e2', // Jackie
  'Qb3bp4fipAPIa5OpCxhyKYhTqzW2', // Hilary
  'lx6wqfTRzYUOKoCobjWD2b2EQSv2', // Kiran
  'vS5XoWmExvQaSwSSZ4qPcUE6noy2'  //Lina
]
function isAdmin(user){
  return admins.includes(user.uid);
}
export default isAdmin;
