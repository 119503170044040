import React from 'react';
const thumb = () => {
  return(
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 40">
    <title>user interface</title>
    <g data-name="Layer 28">
      <path d="M30.00218,16.5085a3.26648,3.26648,0,0,0-3.27983-3.24743l-5.85632.04237a33.21487,33.21487,0,0,0,1.45374-5.13486l.15062-.66468a4.38433,4.38433,0,0,0-3.848-5.35745l-2.03186-.146a.98406.98406,0,0,0-.75575.26772,1.002,1.002,0,0,0-.31634.73664c.01988,2.75757-2.37767,8.12949-4.55159,9.91367a.98964.98964,0,0,0-.98307-.894l-6.99994.02846a.99942.99942,0,0,0-.99593,1.00406L2.053,29.05681a.99942.99942,0,0,0,1.00406.99593l6.99994-.02846a.98743.98743,0,0,0,.96907-.868,11.09813,11.09813,0,0,0,4.31213.84649L23.40257,29.97a3.26093,3.26093,0,0,0,3.13665-4.10751,3.26326,3.26326,0,0,0,1.22885-2.56263,3.22462,3.22462,0,0,0-.11179-.83354,3.26471,3.26471,0,0,0,1.22885-2.56263,3.22462,3.22462,0,0,0-.11179-.83354A3.26278,3.26278,0,0,0,30.00218,16.5085ZM9.04885,28.02836l-5,.02033L3.992,14.04881l5-.02033ZM23.39443,27.97,15.33,28.00282a8.80572,8.80572,0,0,1-4.28551-1.04026L10.99682,15.228c3.0404-1.284,5.95241-7.39365,6.44784-11.16168l1.00714.07305a2.35344,2.35344,0,0,1,1.67975.97267,2.40643,2.40643,0,0,1,.38878,1.94668l-.15254.67152a24.29231,24.29231,0,0,1-1.76012,5.682A1.278,1.278,0,0,0,19.72673,15.312l7.00669-.05095a1.26072,1.26072,0,0,1,.42793,2.44945c-.03407.0121-.05666.04149-.08882.057l-4.06521.01653a1,1,0,1,0,.00813,2l3.84787-.01564a1.23891,1.23891,0,0,1,.02182.14347,1.25283,1.25283,0,0,1-.64828,1.09023.95831.95831,0,0,0-.21661-.04276l-4,.01626a1,1,0,0,0,.00813,2l3.68686-.015a1.25092,1.25092,0,0,1-.78707,1.5413.97758.97758,0,0,0-.23486.15037l-3.65805.01487a1,1,0,1,0,.00813,2l3.5997-.01464c.0008.01757.00783.034.0079.05173A1.26283,1.26283,0,0,1,23.39443,27.97Z"/>
    </g>
    </svg>
  )
}
export default thumb;
