import React, {Component} from 'react';
import Header from '../Header/Header';
import './Team.css';
import hedgehog from './hedgehog.svg';
import blueblob from './blueblob.svg';
import koala from './koala.svg';
import panda from './panda.svg';

const dec = "</>";

const Team = () => {
  return (
    <div class='homeBody'>
      <link href="https://fonts.googleapis.com/css?family=Quicksand&display=swap" rel="stylesheet" />
      <link href="https://fonts.googleapis.com/css?family=Quicksand:500&display=swap" rel="stylesheet"/>
        <Header pagename="team"/>
        <div class="title">
          <h1 id="heading">MEET </h1><h3>cl</h3> <h2> the</h2> <h1 id="heading">TEAM</h1><br />
        </div>
      <table id="team-table">
        <tr>
                <th class="j">JACKIE LIN</th>
                <th class="k">KIRAN VUKSANAJ</th>
                <th class="l">LINA YAMAHARA</th>
                <th class="h">HILARY ZEN</th>
        </tr>
        <tr>
                <td class="j"><img class="profile-img" alt="Thanks to Kristen Lee for drawing these!" src={hedgehog} /></td>
                <td class="k"><img class="profile-img" alt="Thanks to Kristen Lee for drawing these!" src={blueblob} /></td>
                <td class="l"><img class="profile-img" alt="Thanks to Kristen Lee for drawing these!" src={koala} /></td>
                <td class="h"><img class="profile-img" alt="Thanks to Kristen Lee for drawing these!" src={panda} /></td>
        </tr>
        <tr>
                <td class="j">My name is Jackie Lin and I am a rising senior at Stuyvesant High School in New York City. I am interested in studying STEM subjects, especially computer science.</td>
                <td class="k">I am Kiran Vuksanaj - a computer scientist at Stuyvesant High School, with a passion for problem solving and for activism on behalf of the LGBTQ community.</td>
                <td class="l"> My name is Lina Yamahara and I am a rising junior at Trinity School. I'm a passionate activist for the LGBTQ+ community. I hope you enjoy our website!</td>
                <td class="h">I am Hilary, a rising senior at Stuyvesant High School. I live in Manhattan and enjoy computer science! Outside of school, I play piano, swim, and volunteer.</td>
        </tr>
        <tr>
                <td class="j"><a class="site-link" target="_blank" href="https://jackielin655.wixsite.com/summerstem">{dec}</a></td>
                <td class="k"><a class="site-link" target="_blank" href="https://kirvn99.wixsite.com/summerstem">{dec}</a></td>
                <td class="l"><a class="site-link" target="_blank" href="https://linayamahara.wixsite.com/mystory">{dec}</a></td>
                <td class="h"><a class="site-link" target="_blank" href="https://hilaryzen.wixsite.com/summerstem2019">{dec}</a></td>
        </tr>
      </table>
    </div>
  )
}

export default Team;
