const supportData = [{
  "name": "TrevorSpace",
  "desc": "Trevor Support Center is a place where LGBTQ youth and allies can find answers to FAQs and explore resources related to sexual orientation, gender identity and more!",
  "link": "https://www.trevorspace.org/"
},
{
  "name": "GLBT National Resource Database",
  "desc": "The largest collection of  gay, lesbian, bisexual, and transgender resources  on the web. Directly accessthe nation's largest collection of 15,000 GLBT resources. Find the closest social and support resources, community centers, youth groups, and so much more!",
  "link": "https://www.glbtnearme.org/index.html"
},
{
  "name": "CenterLink: The Community of LGBT Centers",
  "desc": "CenterLink develops strong, sustainable LGBT community centers and builds a thriving center network that creates healthy, vibrant communities. CenterLink assists newly forming community centers and helps strengthen existing LGBT centers, through networking opportunities for center leaders, peer-based technical assistance and training, and a variety of capacity building services.",
  "link": "https://www.lgbtcenters.org/LgbtCenters"
},
{
  "name": "It Gets Better",
  "desc": "The It Gets Better Project inspires people across the globe to share their stories and remind the next generation of LGBTQ+ youth that hope is out there.",
  "link": "https://itgetsbetter.org"
},
{
  "name": "We  Are The Youth",
  "desc": "We Are the Youth is an ongoing photojournalism project that shares the stories of lesbian, gay, bisexual, transgender and queer youth in the United States.",
  "link": "http://wearetheyouth.org"
},
{
  "name": "My Story Out Loud",
  "desc": "My Story Out Loud is a digital storytelling project dedicated to uplifting the narratives of LGBTQ+ youth of color and young people living with HIV across the nation by capturing our stories and experiences.",
  "link": "https://mystoryoutloud.org"
},
{
  "name": "LGBT National Help Center Online Peer-Support Chat",
  "desc": "One-on-one confidential peer support. Available from 4pm to 12 am ET Monday through Friday. Also open on Saturday from 12pmto 5pm ET",
  "link": "https://www.glbthotline.org/chat.html"
},
{
  "name": "LGBT National Help Center Trans Teen Online Talk Group",
  "desc": "Weekly moderated group for trans teens ages 12 – 19. Available on Wednesdays from 7pm to 9pm ET",
  "link": "https://www.glbthotline.org/transteens.html"
},
{
  "name": "Lambda Legal",
  "desc": "Lambda Legal, a 501(c)(3) nonprofit, is a national organization committed to achieving full recognition of the civil rights of lesbians, gay men, bisexuals, transgender people and everyone living with HIV through impact litigation, education and public policy work.",
  "link": "https://www.lambdalegal.org"
},
{
  "name": "PFLAG",
  "desc": "PFLAG's 400+ chapter network provides confidential peer support, education and advocacy in communities in nearly all 50 states, the District of Columbia, and Puerto Rico.",
  "link": "https://pflag.org"
},
{
  "name": "Q Card Project",
  "desc": "A simple communication tool that could improve healthcare for queer youth. The Q Card itself has a three-panel design that lets youth fill in their name, pronouns, sexual orientation, gender identity, and provides additional space for youth to list any concerns or questions. The Q Card can then be used to start (or continue) an open and honest conversation with a healthcare provider about privacy, confidentiality, and healthcare needs.",
  "link": "http://www.qcardproject.com/about",
},
{
  "name": "GLSEN",
  "desc": "GLSEN, a national organization working to ensure that LGBTQ students are safe in their schools, has a network of thousands of GSA (Gender and Sexuality Alliances) clubs that are working to make their schools more inclusive. The organization also has dozens of community-led chapters that provide outreach, teacher trainings, and students support.",
  "link": "https://www.glsen.org/students"
}
];

export default supportData;
