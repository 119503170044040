import React, {Component} from 'react';
import firebase from '../firebase';
import Firebase from 'firebase';
import Header from '../Header/Header';
import {Reviews} from '../ResultPage/ResultPage';
import './ProfilePage.css';
import '../ResultPage/ResultPage.css';

const queryString = require('query-string');

class Contributions extends Component {
  constructor(){
    super();
    this.state={
      user:'',
      userData:null,
      reviews:[],
      formDisplay: {
        display:'none',
      },
      bodyDisplay: {
        opacity:'1',
      },
      option:"",
      comments:"",
      doneReview:false,
    }
  }
  componentWillMount(){
    const parsedQuery = queryString.parse(global.location.search);
    var user = parsedQuery.id;
    this.setState({user:user})
    firebase.firestore().doc('users/'+user).get().then(
      (doc) => {
        var data = doc.data();
        this.setState({userData:data});
        this.getReviewContent(doc);
      }
    )
  }
  getReviewContent(doc){
    const db = firebase.firestore();
    let query = db.collectionGroup('reviews').where('user','==',doc.id);
    query.get().then(
      (snapshot) => {
        this.setState({
          reviews:snapshot.docs
        })
      }
    )
  }
  render(){
    if(this.state.userData && this.state.userData.banned){
      return(
        <div>
          <Header />
          <p style={{'color':'#d9534f'}}>This user has been banned.</p>
        </div>
      )
    }
    return(
      <div>
        <div style={this.state.bodyDisplay}>
          <Header />
          <div className="location-card">
            <table>
              <tr>
                <td><h3>Reviews by {(this.state.userData) ? this.state.userData.displayName : ''}</h3></td>
                <td><button onClick={this.handleReport}>Report</button></td>
              </tr>
            </table>
            <Reviews reviews={this.state.reviews} mode="userPage"/>
          </div>
        </div>
        <div class="reportForm" style={this.state.formDisplay}>
          <h2>Report</h2><button onClick={this.handleExit}>X</button>
          <form onSubmit={this.handleSubmit}>
            What are you reporting this user for?<br />
            <input name="option" type='radio' onClick={this.handleClick} value="Spam"/> Spam<br />
            <input name="option" type='radio' onClick={this.handleClick} value="Harassment"/> Harassment or Bullying<br />
            <input name="option" type='radio' onClick={this.handleClick} value="Illegal Activity"/> Illegal Activity<br />
            <input name="option" type='radio' onClick={this.handleClick} value="Hate Speech"/> Hate Speech or Offensive Language<br />
            <input name="option" type='radio' onClick={this.handleClick} value="Other"/> Other<br />
            <textarea maxlength="1000" name="comments" rows="4" cols="58" placeholder="Additional Comments" onChange={this.handleClick} value={this.state.comments}/><br />
            <input type='submit' value="Submit" />
          </form>
        </div>
      </div>
      )
  }

  handleClick = e => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handleSubmit = e => {
    e.preventDefault();
    const db = firebase.firestore();
    var reportedUser = this.state.userData.uid;
    var currentUser = firebase.auth().currentUser.uid;

    if (reportedUser !== currentUser){
      let usersdb = db.collection('users'); //access the reviews database
      let userRef = usersdb.doc(this.state.userData.uid);
      var temp = {
        by: currentUser,
        timestamp:Firebase.firestore.Timestamp.now(),
        reason:this.state.option,
        comments:this.state.comments,
      }
      let reports = userRef.collection('reports');
      reports.doc(currentUser).get()
      .then((doc) => {
        if (doc.exists){
          alert("You have already reported this user.");
          this.handleExit();
        }
        else {
          doc.ref.set(temp);
          alert("Report submitted successfully.");
          this.handleExit();
        }
      });
    }
    else {
      alert("You cannot report yourself.");
      this.handleExit();
    }

    this.setState({
      option:"",
      comments:"",
    });
  }

  handleReport = e => {
    this.setState({
      formDisplay: {display:'block',},
      bodyDisplay: {opacity:'0.35'}
    });
  }

  handleExit = e => {
    this.setState({
      formDisplay: {display:'none'},
      bodyDisplay: {opacity:'1'}
    });
  }

}

export default Contributions;
