const activism = [{
  "name": "Gay Pride Calendar",
  "link": "https://www.gaypridecalendar.com/",
  "desc": "This calendar compiles all the pride events in the US and around the world. Use this website to find events in your city and join the fight for LGBTQ rights, or submit your own pride event!"
},
{
  "name": "WorldPride NYC 2019 Tips for Families",
  "link": "https://www.nycgo.com/articles/nyc-pride-tips-for-families",
  "desc": "Looking to get your kids involved in the pride movement? NYCGo has published a list of tips for families that are attending marches, and compiled a list of LGBTQ+ events for youths and families."
},
{
  "name": "Gay Gatherings",
  "link": "https://2019-worldpride-stonewall50.nycpride.org/events/gay-gatherings/",
  "desc": "Held at the Glass House, this exhibition celebrates the salon and eight gay men who shaped 20th century art and culture: Philip Johnson, David Whitney, John Cage, Merce Cunningham, Lincoln Kirstein, Jasper Johns, Robert Rauschenberg, and Andy Warhol. Buy tickets at the link above!",
  "date": "May 2nd - August 19th"
},
{
  "name": "Camp: Notes on Fashion",
  "link": "https://2019-worldpride-stonewall50.nycpride.org/events/camp-notes-on-fashion/",
  "desc": "This exhibition from the Met's Costume Institute dives into the history of camp and how it is expressed in fashion. Visitors will be able to explore hundreds of objects, stretching from the royal court of Louis XIV in the 17th century to the present. Get tickets at the link above.",
  "date": "May 9th - Sept 8th"
},
{
  "name": "May 17",
  "link": "https://may17.org/",
  "desc": "Every year, the LGBTQ+ community comes together to celebrate May 17th, the International Day against Homophobia, Transphobia, and Biphobia. This website compiles events from all over the world, and even allows you to submit your own event.",
},
]

export default activism;
