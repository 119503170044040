import React, {Component} from 'react';
import Header from '../Header/Header';
import education from './education_img.js';
import fist from './fist_img.js';
import heart from  './heart_img.js';
import phone from './phone_img.js';
import faq from './faq_img.js';
import donate from './donate_img.js';
import './Resources.css';
import {Link} from 'react-router-dom';

class Resources extends Component {
  constructor(props){
    super(props);
    this.state = {
      centerText:''
    };
  }
  handleHover(newText){
    this.setState({centerText:newText});
  }
  render(){
    return (
      <div class='resourcesBody'>
        <Header pagename="resources"/>

          <div class = "resourcesButtons">
            <div class = "topRow">
                      <div className="circle-button" onMouseEnter={() => {this.handleHover('Education')}}>
                        <Link to='/resources/education'>
                          {education()}
                        </Link>
                      </div>
            </div>
            <div class = "firstRow">
                      <div className="circle-button" onMouseEnter={() => {this.handleHover('Activism')}}>
                        <Link to='/resources/activism'>
                          {fist()}
                        </Link>
                      </div>
                      <div className="center-title">  <h1>Resources</h1> </div>
                      <div className="circle-button" onMouseEnter={() => {this.handleHover('Support')}}>
                        <Link to='/resources/support'>
                          {heart()}
                        </Link>
                      </div>
              </div>
            </div>

          <div class = "resourcesbuttons">
             <div class = "secondRow">
                      <div className="circle-button" onMouseEnter={() => {this.handleHover('Donate')}}>
                        <Link to='/resources/donate'>
                          {donate()}
                        </Link>
                      </div>
                      <div className="center-text">  <h1>{this.state.centerText}</h1> </div>
                      <div className="circle-button" onMouseEnter={() => {this.handleHover('FAQ')}}>
                        <Link to='/resources/faq'>
                          {faq()}
                        </Link>
                      </div>
              </div>

              <div class = "bottomRow">
                        <div className="circle-button" onMouseEnter={() => {this.handleHover('Hotlines')}}>
                          <Link to='/resources/hotlines'>
                            {phone()}
                          </Link>
                        </div>
              </div>
          </div>
      </div>

      );
    }
}

export default Resources;
