import React, {Component} from 'react';
import Header from '../Header/Header';
import Site from './Site';
import firebase from '../firebase';
import './Resources.css';
import {Link} from 'react-router-dom';
import hotlines from './hotlineData';


const Hotlines = () => {

  var site = hotlines.map(s => (
    <Site name={s.name} link={s.link} num={s.num} hours={s.hours} desc={s.desc}/>
  ));

    return (
      <div class='resourcesBody'>
        <link href="https://fonts.googleapis.com/css?family=Quicksand&display=swap" rel="stylesheet" />
        <Header pagename="resources"/>
        <div id = "hotlines-body">
          <h1 class="heading">Hotlines</h1>
          <div id="hotline-sites">
            {site}
          </div>
        </div>
      </div>
    );

}

export default Hotlines;
