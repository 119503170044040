import React,{Component} from 'react';
import {Link} from 'react-router-dom';
import Header from '../Header/Header';
import firebase from '../firebase';
import './ResultPage.css';
import AddReview from './AddReview';
import StaticStars from '../Stars/StaticStars';
import thumb from './thumb';
import trash from './trash';
const queryString = require('query-string');

function calcAvg(ratings){
  if(ratings.length===0) return 0;
  var sum = 0;
  ratings.forEach(rating => {sum += parseFloat(rating)});
  return sum / ratings.length;
}

export function updateAvg(reviewCollRef,page,mode){
  reviewCollRef.get().then(
    (snapshot) => {
      let ratings = snapshot.docs.map((doc) => doc.data().rating);
      let newAvg = calcAvg(ratings);
      console.log(snapshot);
      let reviewCount = snapshot.size;
      reviewCollRef.parent.update(
        {
          rating:newAvg,
          reviewCount:reviewCount
        }
      ).then(
        () => {
          if(mode && mode==='addReview'){
            alert('Review successfully added. Thank you for your submission.');
            global.location.search = '?id='+reviewCollRef.parent.id;
          }
        }
      )
      if(page) page.setState({location:Object.assign(page.state.location,{rating:newAvg})});
    }
  )
}

function ratingPercentage(review){
  const {usersWhoRated,positiveCount} = review.data();
  if(usersWhoRated===undefined) return .5;
  return positiveCount / usersWhoRated.length;
}
function reviewComparator(review1,review2){
  return ratingPercentage(review2)-ratingPercentage(review1);
}

const LocationInfo = (props) => {
  var types = "";
  for (var i = 0; i < props.location.type.length; i++) {
    types = types + props.location.type[i] + ", "
  }
  types = types.substring(0, types.length - 2);

  return(
    <div className="location-info">
      <h1>{props.location.name}</h1>
      <p>{types}</p>
      <p>{props.location.description}</p>
      <p>Rating: {props.location.rating===0 ? 'no ratings yet' : props.location.rating+ ' stars'} </p>
    </div>
  )
}

class Review extends Component {
  constructor(props){
    super(props);
    this.state = {
      mode:props.mode,
      review:props.review,
      locationName:'',
      locationID:'',
      dispNames: props.dispNames,
      page: (props.mode==='locationPage') ? props.page : null,
      exists:true
    }
  }
  componentWillMount(){
    const {review} = this.state;
    review.ref.parent.parent.get().then(
      (doc) => {
        this.setState({
          locationName:doc.data().name,
          locationID:doc.id
        });
      }
    )
  }
  deleteMe(){
    /*
    alert('Are you sure you want to delete your review?',
      [
        {text:'Cancel'},
        {text:'Yes, I\'m sure',onClick: () => this.delete()}
      ]
    )
    */
    const db = firebase.firestore();
    const user = firebase.auth().currentUser;
    const { review, page } = this.state;
    const { ref } = review;
    //note: currently, deleting is Super access heavy; might be able to be optimized
    //delete from review
    ref.delete().then(
      () => {
        //delete from user
        let userDoc = db.doc('users/'+user.uid)
        userDoc.get().then(
          (doc) => {
            let {reviews} = doc.data();
            let ind = reviews.findIndex((doc) => {
              return doc.path===review.ref.path
            });
            console.log(ind);
            if(ind !== -1) reviews.pop(ind);
            console.log(reviews);
            userDoc.update({reviews:reviews}).then(
              () => {
                updateAvg(ref.parent,page);
                alert('Review successfully deleted!');
                this.setState({exists:false});
              }
            )
          }
        )
      }
    )
  }
  rate(rating,reviewRef){
    const user = firebase.auth().currentUser;
    if(user && user.emailVerified){
      var usersWhoRated,positiveCount;
      console.log(reviewRef.data());
      if(reviewRef.data().usersWhoRated===undefined){
        usersWhoRated = [];
        positiveCount = 0;
      }else{
        usersWhoRated = reviewRef.data().usersWhoRated;
        positiveCount = reviewRef.data().positiveCount;
      }
      if(usersWhoRated.includes(user.uid)){
        alert('you may only vote on a review once');
      }else{
        console.log('adding');
        positiveCount += rating;
        usersWhoRated.push(user.uid);
        reviewRef.ref.update(
          {
            usersWhoRated:usersWhoRated,
            positiveCount:positiveCount
          }
        ).then(
          () => {
            alert('Rating added!');
            reviewRef.ref.get().then(
              (doc) => {
                this.setState({review:doc});
              }
            )
          }
        )
      }
    }else{
      alert('you must be signed in and verified to rate a review');
    }
  }
  render(){
    const {mode,review,dispNames,exists,locationName,locationID} = this.state;
    const {user,reviewContent,timestamp,usersWhoRated,rating,positiveCount} = review.data();
    if(!exists) return '';
    return(
      <div className="review">
        <div className="title-header">
          <p className="review-user">{
            (mode==="locationPage") ? <Link className="review-link" to={"/contributions?id="+user}>{dispNames[user]}</Link> : <Link className="review-link" to={"/result?id="+locationID}>{locationName}</Link>
          }</p>
          { (firebase.auth().currentUser && user===firebase.auth().currentUser.uid)
          ? <div className="trash-icon" onClick={() => this.deleteMe()}>
              {trash()}
            </div>
          : ''
          }
        </div>
        <p className="review-time">at {timestamp.toDate().toString()}</p>
        <StaticStars starCount={rating} />
        <p className="review-content">{reviewContent}</p>
        <div className="helpful-bar">
          { (usersWhoRated !== undefined)
            ? <p className="helpful-text">{Math.floor(100*positiveCount / usersWhoRated.length)}% of users found this review helpful ({positiveCount}/{usersWhoRated.length})</p>
            : <p className="helpful-text"> Was this review helpful?</p>
          }
          { (firebase.auth().currentUser) && (usersWhoRated === undefined || !usersWhoRated.includes(firebase.auth().currentUser.uid))
          ? <React.Fragment>
              <div className="thumb" id="down" onClick={() => {this.rate(0,review)}}>
                {thumb()}
              </div>
              <div className="thumb" id="up" onClick={() => {this.rate(1,review)}}>
                {thumb()}
              </div>
            </React.Fragment>
          : ''
          }
        </div>
      </div>
    )
  }
}

export const Reviews = (props) => {
  //console.log(reviews);
  return(
    <div className="reviews">
      {props.reviews.map(
        (review) => <Review review={review} dispNames={props.dispNames} mode={props.mode} page={props.page} />
      )}
    </div>
  )
}

class ResultPage extends Component{
  constructor(props){
    super();
    this.state={
      location:null,
      resultID:'',
      addingReview:false,
      userMap:{},
      reviews:[]
    };
  }
  componentWillMount(){
  }
  componentDidMount(){
    const parsedQuery = queryString.parse(global.location.search);
    const db = firebase.firestore();
    const docRef = db.collection('locations').doc(parsedQuery.id);
    docRef.get()
      .then(
        (doc) => {
          if(!doc.exists){
            alert('invalid: location does not exist');
          }else{
            this.setState({
              location:doc.data(),
            });
          }
        }
      );
    const reviewCollRef = docRef.collection('reviews');
    reviewCollRef.get().then(
      (snapshot) => {
        if(!snapshot.empty){
          const reviews = snapshot.docs;
          reviews.sort((r1,r2) => reviewComparator(r1,r2))
          console.log(reviews);
          this.setState({
            reviews:reviews
          });
          this.getUserRef(reviews);
        }else{
          console.log(snapshot);
        }
      }
    )
    this.setState({
      resultID:parsedQuery.id,
      addingReview:(parsedQuery.addReview==='true')
    });
  }
  render(){
    return(
      <div className="resultpage-wrapper">
        <Header />
        {this.state.location ? (
          <div className="location-card">
            <LocationInfo location={this.state.location} />
            {
              (this.state.addingReview) ? (
                <AddReview location={this.state.location} id={this.state.resultID}/>
              ) : (
                ''
              )
            }
            <Reviews reviews={this.state.reviews} dispNames={this.state.userMap} mode="locationPage" page={this}/>
            {
              (this.state.addingReview) ? (
                ''
              ) : (
                <div className="add-review-button-holder">
                  <a className="add-review-button" href={"/result?id="+this.state.resultID+"&addReview=true"}>+</a>
                </div>
              )
            }
          </div>
        ) : <p>loading...</p>}
      </div>
    )
  }
  getUserRef(reviews){
    reviews.forEach(
      (review) => {
        this.addUser(review.data().user);
      }
    );
  }
  addUser(uid){
    const db = firebase.firestore();
    var userDocRef = db.collection('users').doc(uid)
    userDocRef.get().then(
      (doc) => {
        //console.log(doc.data());
        var mapping = this.state.userMap;
        mapping[uid] = doc.data().displayName;
        this.setState({
          userMap:mapping
        });
      }
    )
  }
}
export default ResultPage;
