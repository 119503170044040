import React, {Component} from 'react';
import firebase from '../firebase';
import Header from '../Header/Header';
import './ProfilePage.css';

class ProfilePage extends Component{
  constructor(){
    super();
    this.state = {
      signedIn:false,
      currentUser:null,
      name:null,
      gender:null,
      orientation:null,
      age:null,
    }
  }

  componentWillMount(){
    firebase.auth().onAuthStateChanged(user => {
      if(user){//when user is not 'falsy' i.e. null
        this.setState({
          signedIn:true,
          currentUser: user,
      });
      this.getProfile();
    }
  });
  }

  render(){
    if (this.state.signedIn){
      var user = this.state.currentUser;
      return(
        <div>
          <Header />
          <h1>Your Account Settings</h1>
          <hr /><br />
          <div class="page">
            <div class="sidenav">
              <a class="selected" href="/profile/info">Your Profile</a>
              <a href="/profile/email">Email Reset</a>
              <a href="/profile/password">Password Reset</a>
            </div>
            <div class="main">
              <p class="field">Name</p>
              <p>{(user===null)? <br /> : user.displayName}</p>
              <p class="field">Email</p>
              <p>{(user===null)? <br /> : user.email}</p>
              <p class="field">Age</p>
              <p>{(user===null)? <br /> : (this.state.age===null) ? 'N/A' : this.state.age}</p>
              <p class="field">Gender</p>
              <p>{(user===null)? <br /> : (this.state.gender===null) ? 'N/A' : this.state.gender}</p>
              <p class="field">Sexuality</p>
              <p>{(user===null)? <br /> :
                (this.state.orientation===null)? 'N/A' : this.state.orientation}</p>
            </div>
            <div class="edit">
              <a href="/profile/info-edit">Edit</a>
            </div>
          </div>
        </div>
      )
    }
    else {
    return(
        <div>
          <Header />
          <h1>Your Account Settings</h1>
          <hr /><br />
          <div class="page">
            <div class="sidenav">
              <a class="selected" href="/profile/info">Your Profile</a>
              <a href="/profile/email">Email Reset</a>
              <a href="/profile/password">Password Reset</a>
            </div>
            <div class="main">
            </div>
            <div class="edit">
            </div>
          </div>
        </div>
      )
    }
  }

  getProfile = () => {
    let user = this.state.currentUser;
    const db = firebase.firestore(); //database

    let usersdb = db.collection('users'); //access the reviews database
    let userRef = usersdb.doc(user.uid).get().
    then( (query) => {
      let data = query.data();
      this.setState({
        gender:data.gender,
        orientation:data.orientation,
        age:data.age,
        name:data.displayName,
      });
    });
  }
}

export default ProfilePage;
