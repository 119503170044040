import React from 'react';
const education = () => {
  return(
    <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">

     <g>
      <title>background</title>
      <rect fill="none" id="canvas_background" height="102" width="102" y="-1" x="-1"/>
     </g>
     <g>
      <title>Layer 1</title>
      <path className="edu" id="svg_1" d="m95,36.9l-44.3,-28.6c-0.1,-0.1 -0.2,-0.1 -0.3,-0.1c-0.2,-0.1 -0.3,-0.2 -0.5,-0.2c-0.1,0 -0.2,0 -0.4,0c-0.2,0 -0.3,0 -0.5,0c-0.2,0 -0.3,0.1 -0.4,0.2c-0.1,0 -0.2,0.1 -0.3,0.1l-44.7,28.6c-0.6,0.4 -1,1.1 -1,1.9c0,0.8 0.4,1.5 1,1.9l7.4,4.8c0,0.1 0,0.1 0,0.2l0,40.5c0,1.2 1,2.2 2.2,2.2s2.2,-1 2.2,-2.2l0,-37.7l7.5,4.9c0,0.1 -0.1,0.2 -0.1,0.3l0,21c0,9.6 12.2,18 26,18c14.1,0 26,-8.2 26,-18l0,-20.6l20.2,-13.4c0.6,-0.4 1,-1.1 1,-1.9c0.1,-0.8 -0.3,-1.5 -1,-1.9zm-24.2,37.8c0,7.5 -10.3,14 -22,14c-12.6,0 -22,-7.4 -22,-14l0,-18.7l21.4,14.1c0,0 0.1,0 0.1,0c0.1,0.1 0.3,0.1 0.4,0.2c0.1,0 0.1,0.1 0.2,0.1c0.2,0 0.4,0.1 0.6,0.1c0,0 0,0 0,0c0,0 0,0 0,0s0,0 0,0c0,0 0,0 0,0c0.2,0 0.4,0 0.6,-0.1c0.1,0 0.1,0 0.2,-0.1c0.1,0 0.3,-0.1 0.4,-0.2c0,0 0.1,0 0.1,0l20.1,-13.3l0,17.9l-0.1,0zm-21.3,-9.2l-31.2,-20.5l16.4,-10.9c1,-0.7 1.3,-2.1 0.6,-3.1c-0.7,-1 -2.1,-1.3 -3.1,-0.6l-18,11.9l-5.2,-3.5l40.6,-25.9l40.2,25.9l-40.3,26.7z" />
     </g>
    </svg>
  )
}
export default education
