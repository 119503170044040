const donateData = [{
  "name": "The Trevor Project",
  "link": "https://give.thetrevorproject.org/give/63307/#!/donation/checkout",
  "image": "https://pbs.twimg.com/profile_images/1014972335613304832/UHPQynkH.jpg",
  "desc": "The Trevor Project is a leading national organization that provides crisis intervention and suicide prevention services to LGBTQ+ youth under 25. Donate today to fund their lifelines, suicide prevention trainings, and many other community programs!"
},
{
  "name": "CenterLink",
  "link": "https://www.lgbtcenters.org/Donate",
  "image": "https://www.lgbtcenters.org/Assets/Images/UI/logo-alt.png",
  "desc": "Founded in 1994, CenterLink works to develop and support LGBTQ community centers all over the country and even internationally, helping them increase service capability and access to public resources. Their mission is to build a strong center network that will serve all the needs of the queer community."
},
{
  "name": "LGBT National Help Center",
  "link": "https://donatenow.networkforgood.org/1210845",
  "image": "https://www.proudout.com/wp-content/uploads/2018/08/LGBT-National-Help-Center-3320_237.png",
  "desc": "This nonprofit operates many hotlines and chat rooms for people to talk about sexual orientation or gender identity. They provide safe spaces for people all over the country, and have collections of local resources for 15,000 cities and towns."
},
{
  "name": "Trans Lifeline",
  "link": "https://www.translifeline.org/donate/",
  "image": "https://pbs.twimg.com/profile_images/1065319692674330624/lLOs4FkX_400x400.jpg",
  "desc": "A grassroots nonprofit, Trans Lifeline offers direct support to transgender people with a peer-support crisis hotline, staffed entirely by transgender operators. They also offer Microgrants for issues like legal name changes, to combat the economic issues and suicide epidemic within the trans community."
},
{
  "name": "National Runaway Safeline",
  "link": "https://www.1800runaway.org/donate/",
  "image": "https://www.1800runaway.org/wp-content/themes/app/_/img/logo-1800runaway-registered-tagline-white.png?v=13",
  "desc": "The National Runaway Safeline aims to keep runaway and homeless youth safe. They provide confidential hotlines as well as chat and email resources for both children and parents, and help relay messages or facilitate calls between them."
},
{
  "name": "National Suicide Prevention Lifeline",
  "link": "https://suicidepreventionlifeline.org/donate/",
  "image": "https://pbs.twimg.com/profile_images/1035591248780976128/w3Xl_PAb.jpg",
  "desc": "The Lifeline provides free emotional support to anyone in emotional distress or suicidal distress, along with local care and resources. They also participate in many initiatives to improve public awareness, mental health practices, and suicide prevention."
},
{
  "name": "Fenway Health",
  "link": "https://fenwayhealth.org/give/ways-to-give/",
  "image": "https://fenwayhealth.org/wp-content/uploads/2014/10/tfi-sec-logo-rgb.jpg",
  "desc": "Fenway Health is a Federally Qualified Community Health Center working to improve the health of the LGBTQ community, people living with HIV/AIDS, and the broader population. They have led the national agenda on LGBTQ health and helped start The National Coalition for LGBTQ Health."
},
{
  "name": "National Domestic Violence Hotline",
  "link": "https://www.thehotline.org/donate/",
  "image": "https://www.thehotline.org/wp-content/uploads/sites/3/2018/06/national-domestic-violence3-hotline-logo.jpg",
  "desc": "This nonprofit supports and empowers people struggling with relationship abuse, answering millions of calls over the last 20 years. This is a particularly important issue in LGBTQ relationships, where abusers can leverage societal factors to intimidate and threaten."
},
{
  "name": "It Gets Better Project",
  "link": "https://itgetsbetter.org/donate/",
  "image": "https://www.chconline.org/resourcelibrary/wp-content/uploads/2017/03/itgetsbetter-logo-site-splash-same.png",
  "desc": "This organization is a global project that empowers and connects LGBTQ youth, by telling them that they are not alone. Tens of thousands of people have shared their stories to inspire others, and users are also connected to local community providers for assistance."
},
{
  "name": "We Are The Youth",
  "link": "http://wearetheyouth.org/shop/",
  "image": "http://laurelgolio.com/wp-content/uploads/2015/01/Trevor-1.jpg",
  "desc": "We Are The Youth is a photojournalism project that shares the stories LGBTQ youth across the country, celebrating their individuality and allowing them to make their voice heard in a safe and respectful way. Buy the book or donate to support this ongoing project."
},
{
  "name": "Lambda Legal",
  "link": "https://support.lambdalegal.org/site/Donation2;jsessionid=00000000.app30130a?df_id=6680&mfc_pref=T&6680.donation=form1&NONCE_TOKEN=60235DAAD5DDA8814E004160D420E852",
  "image": "https://www.linkedin.com/media-proxy/ext?w=360&h=360&hash=peNNNTU4Hz2C7QsVVtCCJcXhxCw%3D&ora=1%2CaFBCTXdkRmpGL2lvQUFBPQ%2CxAVta5g-0R69hAxUzh0s4qqAoUq35UsJSpDbTXP4H3Dp5YTcPGitIZKCLPG--AxLKnhQkgQzfPDxHWG2R9CjaNK-P4ke3ca2ccK7aQw5ZRB7iWZI458xKR4",
  "desc": "Lambda Legal is a national legal organization fighting for the civil rights of the LGBTQ community and people living with HIV/AIDS. They do not charge their clients for legal representation and receive no government funding, so they depend on contributions from supporters like you!"
},
{
  "name": "PFLAG",
  "link": "https://pflag.org/supportpflag",
  "image": "http://pflaglouisville.org/PFLAG_Louisville/PFLAG_Louisvilles_History_files/shapeimage_1.png",
  "desc": "With 400 chapters and 200,000 supportors all over the country, PFLAG aims to build a community of LGBTQ people, their families, and their allies to make sure that everyone is respected and valued, no matter what their sexual or gender identity is."
},
{
  "name": "Human Rights Campaign",
  "link": "https://give.hrc.org/page/43662/donate/1?ea.tracking.id=or_gnr_hrc_hometop2019&recurrpay=Y&donationAmt=20&_ga=2.246517249.462534174.1565100075-1521527616.1564672840",
  "image": "https://assets2.hrc.org/files/images/blog/HRCLogo-WithWords-1600x900.png",
  "desc": "Since its founding in 1980, the HRC has fought to end discrimination against the LGBTQ community and create a world where they are accepted as full members of society. They were one of the biggest organizations in the recent fight for marriage equality, and worked tirelessly on the ground to educate Americans."
},
{
  "name": "American Civil Liberties Union",
  "link": "https://action.aclu.org/give/now?redirect=donate",
  "image": "https://www.charities.org/sites/default/files/FoundationLogo_Blue_RGB%20%282%29.png",
  "desc": "For decades, the ACLU has defended the rights in the US Constitution for all groups, no matter what opinions they have or how unpopular they are. The organization continues to fight for the LGBTQ commmunity, and recently struck down a Texas law that banned same-sex intimacy."
},
{
  "name": "GLSEN",
  "link": "https://www.glsen.org/support/giving",
  "image": "https://pbs.twimg.com/profile_images/883341835732754432/vkJPF7lZ.jpg",
  "desc": "GLSEN aims to support LGBTQ students and create safe, affirming school environments. They work with government to implement inclusive safe school policies and support student-led efforts, like the National Student Council, to make their local communities more accepting."
}
]

export default donateData;
