import React from 'react';
import star_empty from './star_empty.svg';
import star_full from './star_full.svg';

const StaticStars = (props) => {
  var out = [];
  for(var i=1;i<=5;i++){
    if(i<=props.starCount){
      out.push(<img className="star-small" src={star_full} alt=""/>);
    }else{
      out.push(<img className="star-small" src={star_empty} alt="" />);
    }
  }
  return(
    <div className="star-holder">
      {out}
    </div>
  )
}

export default StaticStars;
