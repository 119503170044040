const protests = [{
  "name": "LGBTQ Nation",
  "link": "https://www.lgbtqnation.com/tag/protest/",
  "desc": "Stay updated about current LGBTQ+ protests, as well as discrimination against the community, through this news site."
},
{
  "name": "Human Rights Campaign",
  "link": "https://www.hrc.org/",
  "desc": "The HRC is one of the largest civil rights organizations fighting for LGBTQ equality in the US. With millions of members and supporters, they aim to end discrimination against the queer community."
},
{
  "name": "Tell HHS LGBTQ Discrimination in Health Care is Not OK",
  "link": "https://www.hrc.org/blog/tell-hhs-lgbtq-discrimination-in-health-care-is-not-ok",
  "desc": "The Department of Health and Human Services (HHS) recently proposed changes to Section 1557 of the Affordable Care Act, which was the first federal law to prohibit discrimination in health care based on sex. The changes would remove protections against gender identity discrimination, making it even more difficult or expensive for transgender or gender non-conforming people to get health care. Follow the link above to join the HRC's protest and share your thoughts."
},
{
  "name": "Support Trans Student Athletes",
  "link": "https://action.aclu.org/petition/support-trans-student-athletes?ms_aff=NAT&initms_aff=NAT&ms=190726_lgbtrights_transathletepledge&initms=190726_lgbtrights_transathletepledge&ms_chan=web&initms_chan=web",
  "desc": "Transgender student athletes routinely face discrimination, and aren't allowed to compete with the gender that they identify as. Systemic barriers prevent them, especially transgender women, from participating in sports at all, even though Title IX protects all students on the basis of sex. To support these athletes, click the link above to sign the ACLU's petition and make your voice heard."
},
{
  "name": "Day of Silence",
  "link": "https://www.glsen.org/day-silence",
  "desc": "GLSEN's annual Day of Silence is a student-led national event, where participants take a vow of silence to highlight how the LGBTQ community is silenced and erased at school. Students spread awareness about the issue and start a dialogue with administrators about how to create an inclusive environment. Register to get your school involved and stay updated!"
}
]

export default protests;
