import React, {Component} from 'react';
import firebase from '../firebase';

class ResourceAdd extends Component{
  constructor(props){
    super(props);
    this.state = {
      page:props.page,
      enabled:props.page.state.signedIn,
      name:"",
      address:"",
      description:"",
      latitude:0,
      longitude:0,
      type: []
    };
  }
  render(){
    if(this.state.enabled){
      return(
        <div className="newResourceForm">
          <h2>Add New Resource</h2>
          <form onSubmit={(e) => this.uploadResource(e)}>
            <b>Name</b> <input maxlength="64" type="text" name="name" onChange = {(e) => this.updateInput(e)} value={this.state.name} /><br />
            <b>Address</b> <input maxlength="128" type="text" name="address" onChange = {(e) => this.updateInput(e)} value={this.state.address} /><br />
            <b>Type</b> <br />
            <table id="checkbox-table">
              <tr>
                <td><input type="checkbox" name="type" value="Shelter" onChange = {(e) => this.addType(e)} />Shelter</td>
                <td><input type="checkbox" name="type" value="Food" onChange = {(e) => this.addType(e)} />Food</td>
              </tr>
              <tr>
                <td><input type="checkbox" name="type" value="Shopping" onChange = {(e) => this.addType(e)} />Shopping</td>
                <td><input type="checkbox" name="type" value="Hospital" onChange = {(e) => this.addType(e)} />Hospital</td>
              </tr>
              <tr>
                <td><input type="checkbox" name="type" value="School" onChange = {(e) => this.addType(e)} />School</td>
                <td><input type="checkbox" name="type" value="Center" onChange = {(e) => this.addType(e)} />Center</td>
              </tr>
              <tr>
                <td><input type="checkbox" name="type" value="LGBTQ-owned Business" onChange = {(e) => this.addType(e)} />LGBTQ-owned Business</td>
                <td><input type="checkbox" name="type" value="Other" onChange = {(e) => this.addType(e)} />Other</td>
              </tr>
            </table>
            Description<br /><textarea maxlength="150" id="desc-box" rows="4" name="description" onChange = {(e) => this.updateInput(e)} /><br />
            <div id="submit"><input id="submit-button" type="submit" name="submit" value="Submit Location" /></div>
          </form>
        </div>
      )
    }else{
      return(
        <h4 className="verify-alert">Sign in with a verified account to submit a resource</h4>
      )
    }
  }
  updateInput = (e) => {
    this.setState({
      [e.target.name]:e.target.value
    })
  }
  addType = (e) => {
    var tempTypes = this.state.type;
    tempTypes.push(e.target.value);
    this.setState({type:tempTypes});
  }
  uploadResource = (e) => {
    e.preventDefault();
    // alert('beginning geocode');
    this.geocode(this.state.address);
  }

  firebaseUpload(){
    const db = firebase.firestore();
    let collectionRef = db.collection('locations');
    collectionRef.add({
      name:this.state.name,
      address:this.state.address,
      type:this.state.type,
      description:this.state.description,
      location: new firebase.firestore.GeoPoint(parseFloat(this.state.latitude),parseFloat(this.state.longitude)),
      rating:0,
      reviews:[],
      addedBy:firebase.auth().currentUser.email,
      verified:false,
      searchname:this.state.name.toLowerCase(),
    }).then(
      () => {
        alert(this.state.name + ' successfully added to the database at '+this.state.latitude+', '+this.state.longitude + '. It is now pending review.');
        this.state.page.state.map.remove();
        this.state.page.openResourceAdd();
        this.state.page.loadMap();
        this.state.page.getLocations();
      }
    )
  }

  geocode(address){
    const mapquestAPIKey = "JxG8xNRWNOZ6bxAt73Rx4Pb7RPhAxqyz";
    var queryURL = '//www.mapquestapi.com/geocoding/v1/address?key='+mapquestAPIKey+'&location='+address;
    this.getJSON(queryURL,(err,data) => this.getGeo(err,data));
  }
  getGeo(err,data){
    if(err !== null) {
      alert('geocoding failed: '+err);
    }else{
      // alert('geocode success');
      console.log(data);
      // alert('latitude: '+data.results[0].locations[0].latLng.lat);
      // alert('longitude: '+data.results[0].locations[0].latLng.lng);
      this.setState({
        latitude:data.results[0].locations[0].latLng.lat,
        longitude:data.results[0].locations[0].latLng.lng
      });
      //alert(this.state.latitude+' '+this.state.longitude);
      this.firebaseUpload();
    }
  }
  getJSON(url,callback){
    var xhr = new XMLHttpRequest();
    xhr.responseType = 'json';
    // alert(xhr.readyState);
    xhr.open('GET',url,true);
    xhr.onload = function() {
      // alert('loaded');
      var status = xhr.status;
      if(status===200){
        callback(null,xhr.response); //succeeded: status is 200
      }else{
        callback(status,xhr.response); //failed: status is smth else
      }
    }
    xhr.send();
  }
}
export default ResourceAdd;
