import React, {Component} from 'react';
import firebase from '../firebase';
import isAdmin from '../LoginPage/admins';

class Login extends Component{
  constructor(){
    super();
    this.state={
      signedIn: false,
      currentUser:null,
      banned:false,
      addToDatabase: true,
    };
  }

  componentDidMount(){
    const auth = firebase.auth();
    auth.onAuthStateChanged(user => {
      if(user){//when user is not 'falsy' i.e. null
        this.setState({
          signedIn:true,
          currentUser: user
        });
        const db = firebase.firestore(); //database
        let usersdb = db.collection('users'); //access the reviews database
        usersdb.doc(user.uid).get().then(userDoc=>{
          if(!userDoc.exists){
            user.sendEmailVerification();
            db.collection("users").doc(user.uid).set({
              email: user.email,
              uid: user.uid,
              displayName: user.displayName,
              age:null,
              gender:null,
              orientation:null,
              reviews: [],
            });
          }else{
            // user doc exists: check if banned
            const {banned} = userDoc.data();
            if(banned){
              console.log('banned!');
              auth.signOut();
              this.setState({
                signedIn:false,
                currentUser:null,
                banned:true
              })
            }
          }
        });
      }
    });
  }

  render(){
    var user = this.state.currentUser;
    if(this.state.signedIn){
      if (user.emailVerified){
        return(
          <div className="top">
            <div>
              Hello {this.state.currentUser.displayName}!
              <div className="dropdown">
                <button className="dropbtn">v</button>
                <div className="dropdown-content">
                  <a href="/profile/info">Account Settings</a>
                  <a href={"/contributions?id="+this.state.currentUser.uid}>Contributions</a>
                  {isAdmin(user) ? <div><a href="/administrator">Administrator</a></div> : ''}
                </div>
              </div>
            </div>
            <div id="login-button">
              <a href="/" onClick={() => {this.logOut()}}> Logout</a>
            </div>
          </div>
        )
      }
      else{
        return(
          <div class="top">
            <div>
              Hello {this.state.currentUser.displayName}!
              <div class="dropdown">
                <button class="dropbtn">v</button>
                <div class="dropdown-content">
                  <a href="/profile/info">Account Settings</a>
                </div>
              </div>
            </div>
            <div id="verify">
              <button id="verify-button" onClick={() => {user.sendEmailVerification()}}>
                You have not verified your email yet. Click here to send another verification email.
                </button>
            </div>
            <div id="login-button">
              <a href="/" onClick={() => {this.logOut()}}> Logout</a>
            </div>
          </div>
        )
    }
  }
  else {
    if(this.state.banned){
      return(
        <div className="top bannedMessage">
          You have been banned from using queery for abusing our services. To appeal this ban, please email kirvn99 (at) gmail (dot) com
        </div>
      );
    }
      return(
        <div id="login-button">
          <a href="/login">Login</a>
        </div>
      )
    }
  }
  logOut(){
    firebase.auth().signOut()
      .then(function() {
        this.forceUpdate();
      })
      .catch(function(error) {

      });
  }

}

export default Login;
