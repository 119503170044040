import React from 'react';
import {BrowserRouter as Router,Route} from 'react-router-dom';
import Home from './Home/Home.js';
import Map from './Map/Map.js';
import LoginPage from './LoginPage/LoginPage.js';
import Resources from './Resources/Resources.js';
import ResultPage from './ResultPage/ResultPage';

import Activism from './Resources/Activism.js'
import Donate from './Resources/Donate.js'
import Support from './Resources/Support.js'
import Hotlines from './Resources/Hotlines.js'
import Faq from './Resources/Faq.js'
import Education from './Resources/Education.js'

import Team from './Team/Team.js';

import ProfilePage from './LoginPage/ProfilePage.js';
import PasswordReset from './LoginPage/PasswordReset.js';
import ProfilePageEdit from './LoginPage/ProfilePageEdit.js';
import EmailReset from './LoginPage/EmailReset.js';
import Contributions from './LoginPage/Contributions.js';
import Administrator from './LoginPage/Administrator.js';


const Routes = () => {
  return (
    <Router>
      <div>
        <Route exact path={"/"} component={() => <Home />} />
        <Route exact path={"/map"} component={() => <Map />} />
        <Route exact path={"/resources"} component={() => <Resources />} />
        <Route exact path={"/team"} component={() => <Team />} />
        <Route exact path={"/login"} component={() => <LoginPage />} />
        <Route exact path={"/resources/activism"} component={() => <Activism />} />
        <Route exact path={"/resources/donate"} component={() => <Donate />} />
        <Route exact path={"/resources/support"} component={() => <Support />} />
        <Route exact path={"/resources/education"} component={() => <Education />} />
        <Route exact path={"/resources/faq"} component={() => <Faq />} />
        <Route exact path={"/resources/hotlines"} component={() => <Hotlines />} />
        <Route exact path={"/profile/info"} component={() => <ProfilePage />} />
        <Route exact path={"/profile/info-edit"} component={() => <ProfilePageEdit />} />
        <Route exact path={"/profile/email"} component={() => <EmailReset />} />
        <Route exact path={"/contributions"} component={() => <Contributions />} />
        <Route exact path={"/administrator"} component={() => <Administrator />} />
        <Route exact path={"/profile/password"} component={() => <PasswordReset />} />
        <Route path={"/result"} component={() => <ResultPage />} />
      </div>
    </Router>
  )
}

export default Routes;
